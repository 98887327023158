import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsComponent } from './clients.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: "",
    component: ClientsComponent,
    children: [
      {
        path: "",
        component: ClientsComponent,
        children: [
          {
            path: '',
            component: ClientsComponent,
          },
        ]
      }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  declarations: [ClientsComponent]
})
export class ClientsModule { }
