import { Injectable } from '@angular/core';

declare global {
	interface Window {
		OneSignal: any;
	}
}

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  oneSignal: any = []

  constructor() { }

  onInit(appId: string):void {
    this.onLoad().then((OneSignal)=>{
      OneSignal.init({
        // appId: "7148b22c-0e1a-4fd6-99ca-ab4aa19e06b8", //localhost
        // appId: "f20d0d70-d446-4250-8bbe-ae822d1c9a70", //airi.freedomks.tk
        // appId: "66901962-3562-47e0-8705-51d879d37870", //airi.freedomks.tk admin
        appId: appId,
        notifyButton: {
          enable: true,
        },
      });
    });
  }

  async onLoad(): Promise<any> {
    window.OneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      window.OneSignal.push(function() {
        resolve(window.OneSignal);
      });
    });
  }
}