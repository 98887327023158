import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  @Input() slider: any;
  @Input() size = 15;

  constructor() { }

  ngOnInit(): void {
    this.slider.center = ((this.slider.max + this.slider.min) / 2).toFixed(0);
    this.slider.value = this.slider.value || [this.slider.center];
    this.slider.unit = this.slider.unit || '';
  }
}
