import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { AuthComponent } from './auth/auth.import';
// import { PageNotFoundComponent } from './page-not-found/page-not-found.import';
// import { SystemComponent, SYSTEM } from './system/system.import';
import { SYSTEM } from './system/system.model';

const routes: Routes = [ 
  { path: 'login', loadChildren: './auth/auth.module#AuthModule' },
  { path: '', redirectTo: SYSTEM.menu.dashboard, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
