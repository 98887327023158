import { LANGUAGES } from './languages';

export const INPUT_MODE = {
  check: 'check',
  state: 'state',
  slider: 'slider',
  text: 'text',
};

export const ACTION_SCRIPT_PARAMETER = {
  relay: [
    {
      field: 'state',
      key: 'state',
      type: 'boolean',
      // value: [false],
      input: INPUT_MODE.state,
    },
  ],
  fan: [
    {
      field: 'speed',
      key: 'speed',
      type: 'number',
      // value: [0],
      input: INPUT_MODE.slider,
      unit: LANGUAGES.EN.unit.percent,
      max: 100,
      min: 0,
    },
    {
      field: 'swing',
      key: 'swing',
      type: 'boolean',
      // value: [false],
      input: INPUT_MODE.state,
    },
    {
      field: 'misting',
      key: 'misting',
      type: 'boolean',
      // value: [false],
      input: INPUT_MODE.state,
    },
  ]
};

export const ACTION_SCRIPT_EFFECT = {
  changeTo: 'CHANGE_TO',
  adjust: 'ADJUST',
  formula: 'FORMULA',
};

export const ACTION_CONDITION_PARAMETER = {
  temperature: [
    {
      field: 'temperature',
      key: 'temp',
      type: 'number',
      value: '0',
      input: INPUT_MODE.text,
      max: 1000,
      min: -1000,
      unit: LANGUAGES.EN.unit.celsius,
    },
  ],
  tempHumi: [
    {
      field: 'temperature',
      key: 'temp',
      type: 'number',
      value: '0',
      input: INPUT_MODE.text,
      max: 1000,
      min: -1000,
      unit: LANGUAGES.EN.unit.celsius,
    },
    {
      field: 'humidity',
      key: 'humi',
      type: 'number',
      // value: [0],
      input: INPUT_MODE.slider,
      unit: LANGUAGES.EN.unit.percent,
      max: 100,
      min: 0,
    },
  ]
};

export const ACTION_CONDITION_DOIF = {
  gt: 'GT',
  lt: 'LT',
  eq: 'EQ',
  diff: 'DIFF'
};

export const ACTION_SCHEDULE_REPEAT = {
  minute: 'MIN',
  hour: 'HOUR',
  day: 'DAY',
  week: 'WEEK',
  month: 'MONTH',
  year: 'YEAR',
};

export const DEVICE_TYPE = {
  temperature: 'TEMPERATURE',
  tempHumi: 'TEMP_HUMI',
  repeater: 'REPEATER',
  incubator: 'INCUBATOR',
  relay: 'RELAY',
  fan: 'FAN',
};

export const DEVICE_SETTINGS = {
  incubator: {
      temperature: 'temperature_target',
      temperature_power_min: 'temperature_power_min',
      temperature_power_max: 'temperature_power_max',
      humidity: 'humidity_target',
      humidity_power_min: 'humidity_power_min',
      humidity_power_max: 'humidity_power_max',
      swing_time: 'swing_time',
      date_begin: 'date_begin',
  }
};
