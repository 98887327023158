import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SYSTEM } from '../system/system.model';

import { LANGUAGES } from '../_services/languages';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  language: any = LANGUAGES.VI;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    if (this.authService.checkToken()) {
        this.router.navigate([SYSTEM.menu.dashboard]);
    } else {
      this.router.navigate(['/login']);
    }
  }

  changeLanguage(language: string): void {
    switch (language) {
      case 'English':
        this.language = LANGUAGES.EN;
        break;
      default:
        this.language = LANGUAGES.VI;
        break;
    }
  }
}
