<div class="bma_header">
  <h3 class="bma_hdr_title">{{language.common.automation}}</h3>
  <div class="bma_hdr_breadcrums"> 
    <a class="bma_hdr_brc_tab" (click)="onChangeTab(tab)">{{tab}}</a> 
  </div>
</div>
<div class="bma_content">
  <div class="row">
    <div class="col-12">
      <div class="bma_cnt_portlet">
        <div class="bma_cnt_plt_header">
          <a class="bma_cnt_plt_hdr_tab" [ngClass]="{'bma_tab_active': tab===language.automation.actions}"
          (click)="onChangeTab(language.automation.actions)">
            <i class="bi bi-collection-play"></i>
            <span style="padding-left: 10px;">{{language.automation.actions}} ({{0}})</span>
          </a>
        </div>
        <div class="bma_cnt_plt_body">
          <app-actions *ngIf="tab===language.automation.actions"></app-actions>
        </div>
      </div>
    </div>
  </div>
</div>
