<div class="cm_multiselect" clickOutside (clickOutside)="selectDrop(false)">
  <div class="cm_selected" (click)="selectDrop(!filter.isDrop)">
      <span *ngIf="filter.selectedName.length===0 || filter.selectedName.length > 2">{{language.itemInfo.select + ' '}}</span>
      <span style="text-transform: lowercase;" *ngIf="filter.selectedName.length===0">{{filter.title}}</span>
      <span>    
          {{filter.selectedName.length > 2 ? filter.selectedName.length + ' ' + filter.title: filter.selectedName + ''}}
      </span>
      <i class="cm_slt_icon bi bi-chevron-down"></i>
  </div>
  <div class="cm_selects" *ngIf="filter.isDrop">
      <div class="cm_slt_header">
          <label class="ss_checkbox">
              <input type="checkbox" [(ngModel)]="filter.isSelectAll" (change)="selectAll()">
              <span class="ss_checkmark"></span>
          </label>
          <div class="cm_slt_search">
              <i class="cm_sch_icon bi bi-search"></i>
              <input class="cm_sch_name" type="text" (keyup)="filterName($event)">
          </div>
      </div>
      <div class="cm_slt_row" [ngClass]="{'cm_row_active': item.isSelected}"
          *ngFor="let item of list">
          <label class="ss_checkbox" style="margin-left: 10px;">
              <input type="checkbox" [(ngModel)]="item.isSelected" (change)="checkSelected(item.index)">
              <span class="ss_checkmark"></span>
          </label>
          <div class="cm_slt_name" (click)="item.isSelected=!item.isSelected; checkSelected(item.index)">
              <span>{{item.name}}</span>
          </div>
      </div>
  </div>
</div>