<div class="ss_popup">
    <div class="ss_popup_backgound"></div>
    <div class="ss_popup_content">
        <div class="ss_pu_header">
            <div class="ss_pu_hdr_title">Device Detail</div>
            <i class="bi bi-x ss_pu_hdr_close" (click)="closePopup()"></i>
        </div>
        <!-- <div class="ss_pu_body">
    
        </div> -->
    </div>
</div>