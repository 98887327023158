import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

import { LANGUAGES } from '../../../../_services/languages';

import { DeviceChart } from './device-detail.model';
import { DEVICE_TYPE } from '../../../../_services/constant';
import { SystemService } from '../../../system.service';
import { DeviceDetailService } from './device-detail.service';

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss']
})
export class DeviceDetailComponent implements OnInit {
  language: any = LANGUAGES.LANG;
  deviceType = DEVICE_TYPE;
  deviceId = this.router.url.split('/')[3] || 'No Name';
  device: any;
  chartRealtime = this.createChartLine(0, [], 0, true, []);

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private location: Location,
    private systemService: SystemService,
    private deviceDetailService: DeviceDetailService,
  ) { }

  ngOnInit() {
    this.deviceDetailService.getDevice(this.deviceId);
    this.systemService.getEventStreamDevice().subscribe(device => {
      if (device && device.id == this.deviceId) {
          this.device = device;
          this.updateDeviceInfo();
      }
    });
  }

  goBack() {
    this.location.back();
  }

  updateDeviceInfo() {
    let now = Math.floor(Date.now()/1000);
    this.language = LANGUAGES.LANG;
    let deviceValues = this.language.deviceValues;
    let deviceUnit = this.language.unit;
    switch (this.device.deviceType) {
      case DEVICE_TYPE.incubator:
        this.device.type = this.language.deviceType.incubator || '';
        this.device.data = [
          { name: this.language.itemInfo.error, value: 'Code_' + this.device.metadata.error} || '',
          { name: `${deviceValues.temperature} ${deviceUnit.celsius}`, value: this.device.metadata.temperature} || '',
          { name: `${deviceValues.temperaturePower} ${deviceUnit.percent}`, value: this.device.metadata.temperature_power} || '',
          { name: `${deviceValues.humidity} ${deviceUnit.percent}`, value: this.device.metadata.humidity } || '',
          { name: `${deviceValues.humidityPower} ${deviceUnit.percent}`, value: this.device.metadata.humidity_power} || '',
          { name: deviceValues.swingTimer, value: this.datePipe.transform(new Date(this.device.metadata.swing_timer*1000), 'HH:mm:ss', '+0') || ''}
        ];
        console.log('device.data', this.device.data);
        this.device.setting = [
          { name: `${deviceValues.temperature} ${deviceUnit.celsius}`, value: this.device.settings.temperature_target}  || '',
          { 
            name: `${deviceValues.temperaturePower} \
                  ${deviceValues.threshold.max} \
                  ${deviceUnit.percent}`, 
            value: this.device.settings.temperature_power_max
          }  || '',
          {
            name: `${deviceValues.temperaturePower} \
                  ${deviceValues.threshold.min} \
                  ${deviceUnit.percent}`,
            value: this.device.settings.temperature_power_min
          }  || '',
          { name: `${deviceValues.humidity} ${deviceUnit.percent}`, value: this.device.settings.humidity_target}  || '',
          {
            name: `${deviceValues.humidityPower} \
                  ${deviceValues.threshold.max} \
                  ${deviceUnit.percent}`,
            value: this.device.settings.humidity_power_max
          }  || '',
          {
            name: `${deviceValues.humidityPower} \
                  ${deviceValues.threshold.min} \
                  ${deviceUnit.percent}`,
            value: this.device.settings.humidity_power_min
          }  || '',
          { name: deviceValues.swingTimer, value: this.datePipe.transform(new Date(this.device.settings.swing_time*1000), 'HH:mm:ss', '+0') || ''},
          { name: deviceValues.dateBegin, value: this.datePipe.transform(new Date(this.device.settings.date_begin*1000), 'EEEE dd/MM/yyyy HH:mm:ss') || ''},
          { name: deviceValues.daysGone, value: Math.floor((now - this.device.settings.date_begin)/86400)},
        ]

        if (this.chartRealtime.dataSets.length != 2) {
          console.log("createChartLine");
          let colors = [
            { borderColor: '#dc143c'},
            { borderColor: '#1e90ff'},
          ];
          let labels = [
            deviceValues.temperature,
            deviceValues.humidity,
          ];
          this.chartRealtime = this.createChartLine(2, labels, 0, true, colors);
        }
        
        if (this.chartRealtime.dataSets[0].data && this.chartRealtime.dataSets[0].data.length > 20) {
          this.chartRealtime.dataSets[0].data = this.chartRealtime.dataSets[0].data?.slice(1);
          this.chartRealtime.dataSets[1].data = this.chartRealtime.dataSets[1].data?.slice(1);
          this.chartRealtime.labels = this.chartRealtime.labels.slice(1);
        }
        this.chartRealtime.dataSets[0].data?.push(this.device.metadata.temperature);
        this.chartRealtime.dataSets[1].data?.push(this.device.metadata.humidity);
        this.chartRealtime.labels.push(this.datePipe.transform(new Date(this.device.amqpModifiedAt), 'HH:mm:ss') || '');
        break;
      default:
        this.device.type = '';
        this.device.data = [];
        break;
    }
  }

  createChartBar(label: string, tooltip: boolean, color: string) {
    let chart: DeviceChart = {
      dataSets: [
        { 
          data: [], 
          label: label,
          hideInLegendAndTooltip: !tooltip,
        }
      ],
      labels: [],
      options: {
        responsive: true,
      },
      colors: [
        {
          backgroundColor: color,
        },
      ],
      legend: true,
      plugins: [],
      type: 'bar',
    };

    return chart;
  }

  createChartLine(number: Number, labels: string[], tension: number, tooltip: boolean, colors: any[]) {
    
    let dataSets: ChartDataSets[] = [];

    for (let i = 0; i < number; i++) {
      dataSets.push({
        data: [], 
        label: labels[i],
        lineTension: tension,
        hideInLegendAndTooltip: !tooltip,
      });
      colors[i].backgroundColor = 'transparent';
    }

    let chart: DeviceChart = {
      dataSets: dataSets,
      labels: [],
      options: {
        responsive: true,
      },
      colors: colors,
      legend: true,
      plugins: [],
      type: 'line',
    }

    return chart;
  }
}
