<div class="cpn_selection" clickOutside (clickOutside)="isDrop=false">
    <div class="cpn_selected" (click)="selectDrop()">
        <span *ngIf="share">{{name ? name + '' : language.itemInfo.select + ' ' + selection.title}}</span>
        <span *ngIf="!share">{{selected.names.length ? selected.names + '' : language.itemInfo.select + ' ' + selection.title}}</span>
        <i class="cpn_slt_icon bi bi-chevron-down"></i>
    </div>
    <div class="cpn_selects" *ngIf="isDrop">
        <div class="cpn_slt_header">
            <div class="cpn_slt_search" *ngIf="searchName">
                <i class="cpn_slt_sch_icon bi bi-search"></i>
                <input class="cpn_slt_sch_name" type="text" (keyup)="filterName($event)">
            </div>
        </div>
        <div class="cpn_slt_list" *ngIf="unSelection">
            <div class="cpn_slt_name" (click)="changeSelection(-1)">
                <span>{{language.itemInfo.select + ' ' + selection.title}}</span>
            </div>
        </div>
        <div class="cpn_slt_list" [ngClass]="{'cpn_slt_row_active': selection.name===name}"
            *ngFor="let item of list">
            <div class="cpn_slt_name" *ngIf="!selected.names.includes(item.name)"
                (click)="changeSelection(item.index)">
                <span>{{item.name || language.itemInfo.select + ' ' + selection.title}}</span>
            </div>
        </div>
    </div>
</div>