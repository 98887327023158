import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LANGUAGES } from '../../../_services/languages';
import { SYSTEM } from '../../system.model';
import { DEVICE_LAYOUT } from './device-layout.model';

import { DevicesService } from './devices/devices.service';

@Component({
  selector: 'app-device-layout',
  templateUrl: './device-layout.component.html',
  styleUrls: ['./device-layout.component.scss']
})
export class DeviceLayoutComponent implements OnInit {

  language: any = LANGUAGES.LANG;
  numberDevices = 0;
  deviceLayout = DEVICE_LAYOUT;
  tab = this.language.common.devices;
  
  constructor(
    private router: Router,
    private devicesService: DevicesService,
  ) { }

  ngOnInit() {
    this.language = LANGUAGES.LANG;
    this.tab = this.language.common.devices;
  }

  onChangeTab(tab: string) {
    this.tab = tab;
  }

  getNumberDevices() {
    return this.devicesService.numberDevices;
  }
}
