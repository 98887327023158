import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { StompService} from '@stomp/ng2-stompjs';
import { IMessage, Message } from '@stomp/stompjs';

import { rabbitmqConfig , rabbitmqHeaders} from '../_services/rabbitmq';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private eventStreamDevice = new Subject<any>();
  private eventStreamGateway = new Subject<any>();

  isMenuFull = true;
  isMenuClose = false;
  isMenuToggle = false;

  stompService = new StompService(rabbitmqConfig);

  constructor() {
  }

  apiCheckError(error: any) {
    if (error.status == 401) {
      localStorage.setItem('auth', '{}');
    }
  }

  closeMenu(isMenuClose: boolean) {
    this.isMenuClose = isMenuClose;
  }

  toggleMenu() {
    this.isMenuFull = !this.isMenuFull;
  }

  getEventStreamDevice(): Observable<any> {
    return this.eventStreamDevice.asObservable();
  }

  setEventStreamDevice(data: any) {
    this.eventStreamDevice.next(data);
  }

  getEventStreamGateway(): Observable<any> {
    return this.eventStreamGateway.asObservable();
  }

  setEventStreamGateway(data: any) {
    this.eventStreamGateway.next(data);
  }

  getRabbitWebStomp({
    queueName = '', 
    topics = <string[]>[], 
    headers = rabbitmqHeaders,
  }) : void {
     const stompSubscriptions = topics.map(topic => {
    
      return this.stompService.subscribe(topic, 
        {
          "x-queue-name": queueName,
          "exclusive": 'true',
        }
      );
    });

    stompSubscriptions.map(stompSubscription => stompSubscription.subscribe((msg: Message) => {
      const msgJson = JSON.parse(msg.body);
      if (msgJson.device) {
        // console.log("change stream for device", msgJson.device);
        this.setEventStreamDevice(msgJson.device);
      } else if (msgJson.gateway) {
        // console.log('change stream for gateway');
        this.setEventStreamGateway(msgJson.gateway);
      }
    }));

    // var isRWSReconn = true;
    // setInterval(() => {
    //   if (isRWSReconn) {

    //     var ws = new WebSocket('wss://rabbitmq.freedomks.tk:12467/ws');
    //     var client = Stomp.over(ws);

    //     var onConnect = function() {
    //       console.log('getAmqpWebStomp connected');

    //       isRWSReconn = false;
    //       let id = client.subscribe(`/topic/stream.reply.*`, function (msg: any) {
    //         console.log("AmqpWebStomp msg " + msg.body);

    //         const msgJson = JSON.parse(msg.body);
    //         if (msgJson.device) {
    //           console.log('change stream for device');
    //           deviceService.updateDevice(msgJson.device);
    //         }
    //         else {
    //           console.log('change stream for other');
    //         }
    //       });
    //     };
    
    //     var onError = function() {
    //       console.log('getAmqpWebStomp error');
          
    //       client.abort("AWS Error");
    //       isRWSReconn = true;
    //     };
    
    //     client.connect('web_airi', 'web_airi_pass', onConnect, onError, 'airi');
    //   }   
    // }, 5000);
  }
}
