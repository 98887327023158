<div class="auth">
    <div class="auth_login">
        <div class="login_logo">
          <h3 class="auth_title">Airi</h3>
        </div>
        <app-login [ngClass]="{'testClass': false}" [language]="language"></app-login>
        <div class="auth_language">
          <span>{{language.auth.language}}: </span>
          <a (click)="changeLanguage('English')">English</a>
          <span> | </span>
          <a (click)="changeLanguage('Việt Nam')">Việt Nam</a>
        </div>
      </div>
    <div class="auth_introduction">
      <img style="width: 100%;" src="http://airi.freedomks.ml/assets/app/media/img//bg/bg1.png">
    </div>
</div>

