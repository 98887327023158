import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ClientsService } from '../../clients/clients.service';
import { GatewaysService } from '../gateways/gateways.service';

import { AUTH } from '../../../../_services/local.storage';
import { LANGUAGES } from '../../../../_services/languages';
import { DEVICE_TYPE } from '../../../../_services/constant';

@Component({
  selector: 'app-gateways',
  templateUrl: './gateways.component.html',
  styleUrls: ['./gateways.component.scss']
})
export class GatewaysComponent implements OnInit {

  auth = AUTH;
  language: any = LANGUAGES.LANG;
  numberGateways = 0;
  gateways: any[] = [];
  
  popup = {
    name: '',
    deviceDetail: 'deviceDetail',
    commonSettings: 'commonSettings',
    advanceSettings: 'advanceSettings',
    changeState: {
      name: 'changeState',
      active: false,
      type: '',
      devices: <any>[],
      state: false,
    }
  }

  isSelectedAll: boolean = false;
  selected = {
    number: 0,
    index: 0,
  }

  recordStart = 0;
  recordEnd = 0;
  pageIndex = 0;
  pageIndexs = [0, 1, 2];
  pageSize = 10;
  isPageSizeUp = false;
  
  filterWhere: any = {};
  filter = <any> {
    main: {
      isDrop: false,
      isChange: false,
    },
    name: {
      title: 'name',
      selectedValue: ''
    },
    clients: {
      title: this.language.common.client,
      list: <any>[],
      selectedValue: <any>[],
    },
    status: {
      title: this.language.itemInfo.status,
      list: [
        {
          name: this.language.itemInfo.statusOnline,
          value: true,
          isSelected: false
        },
        {
          name: this.language.itemInfo.statusOffline,
          value: false,
          isSelected: false
        }
      ],
      selectedValue: <any>[],
    },
  }

  constructor(
    private router: Router,
    private gatewaysService: GatewaysService,
    private clientsService: ClientsService,
  ) { 
  }

  async ngOnInit() {
    this.language = LANGUAGES.LANG;
    this.gatewaysService.language = LANGUAGES.LANG;
    this.gatewaysService.getEventStreamGateway();
    await this.getFilters();
    await this.getGateways();
  }

  async getFilters() {
    let filter: any = localStorage.getItem('gatewaysFilter');
    filter = filter ? JSON.parse(filter) : {};
    console.log("oldFilter", filter);

    await this.getFilterName(filter.name || '');
    await this.getFilterClients(filter.clients || []);
    await this.getFilterStatus(filter.status || []);
  }

  async getFilterName(oldFilter: string) {
    if (oldFilter) {
      this.filter.name.selectedValue = oldFilter;
      this.filterWhere.name = { "like":`%${this.filter.name.selectedValue}%`, "options":"i"};
    } else {
      delete this.filterWhere.name;
    }
  }

  async getFilterClients(oldFilter: any) {
    this.filter.clients.list = JSON.parse(await this.clientsService.getClients('{}')).data;
    this.filter.clients.selectedName = [];
    this.filter.clients.selectedValue = [];
    for (let i = 0; i < this.filter.clients.list.length; i++) {
      this.filter.clients.list[i].value = this.filter.clients.list[i].id;
      if (oldFilter.includes(this.filter.clients.list[i].value)) {
        this.filter.clients.list[i].isSelected = true;
        this.filter.clients.selectedName.push(this.filter.clients.list[i].name);
        this.filter.clients.selectedValue.push(this.filter.clients.list[i].value);
      }
    }

    if (this.filter.clients.selectedValue.length == 0) {
      delete this.filterWhere.clientId;
    } else {
      this.filterWhere.clientId = { "inq": this.filter.clients.selectedValue};
    }
  }

  getFilterStatus(oldFilter: any) {
    this.filter.status.selectedName = [];
    this.filter.status.selectedValue = [];
    for (let i = 0; i < this.filter.status.list.length; i++) {
      if (oldFilter.includes(this.filter.status.list[i].value)) {
        this.filter.status.list[i].isSelected = true;
        this.filter.status.selectedName.push(this.filter.status.list[i].name);
        this.filter.status.selectedValue.push(this.filter.status.list[i].value);
      }
    }

    if (this.filter.status.selectedValue.length != 1) {
      delete this.filterWhere.isOnline;
    } else {
        this.filterWhere.isOnline = this.filter.status.selectedValue[0];
    }
  }

  submitFilterGateway(checkFilterChange: boolean) {

    if (checkFilterChange) this.checkFilterChange();

    let oldFilter = {
      name: this.filter.name.selectedValue || '',
      clients: this.filter.clients.selectedValue || [],
      status: this.filter.status.selectedValue || [],
    }

    localStorage.setItem('gatewaysFilter', JSON.stringify(oldFilter));
    this.getGateways();
    this.filter.main.isDrop = false;
  }

  async checkFilterChange() {
    for (let key in this.filter) {
      if (this.filter[key].isDrop && this.filter[key].isChange) {
        this.filter[key].isChange = false;
        this.changeFilterWhere(this.filter[key].title);
      }
    }
  }

  changeFilterWhere(name: string) {
    switch (name) {
      case this.filter.name.title:
        if (!this.filter.name.selectedValue) {
          delete this.filterWhere.name;
        } else {
          this.filterWhere.name = { "like":`%${this.filter.name.selectedValue}%`, "options":"i"};
        }
        break;
      case this.filter.clients.title:
        if (this.filter.clients.selectedValue.length == 0) {
          delete this.filterWhere.clientId;
        } else {
          this.filterWhere.clientId = { "inq": this.filter.clients.selectedValue};
        }
        break;
      case this.filter.status.title:
        if (this.filter.status.selectedValue.length != 1) {
          delete this.filterWhere.isOnline;
        } else {
            this.filterWhere.isOnline = this.filter.status.selectedValue[0];
        }
        break;
    }

    this.filter.main.isChange = true;
  }

  filterRefresh(filter: any) {
    filter.selectedName = [];
    filter.selectedValue = [];
    for (let i = 0 ; i < filter.list.length; i++) {
      filter.list[i].isSelected = false;
    }
  }

  filterDrop() {
    this.filter.main.isDrop = !this.filter.main.isDrop;
    this.checkFilterChange();
    if (!this.filter.main.isDrop && this.filter.main.isChange) {
      this.submitFilterGateway(false);
    }
  }
  
  async getGateways() {
    let filter = `{"where":${JSON.stringify(this.filterWhere)},"limit":${this.pageSize},"skip":${this.pageSize*this.pageIndex},"override":true}`;
    let where = {...this.filterWhere, override: true };
    this.gatewaysService.countGateways(JSON.stringify(where));
    this.gatewaysService.updateGateways(JSON.parse(await this.gatewaysService.getGateways(filter)).data);

    this.gateways = this.gatewaysService.gateways;
    this.numberGateways = this.gatewaysService.numberGateways;
    if (this.gatewaysService.gateways.length > 0) {
      this.recordStart = this.pageSize*this.pageIndexs[this.pageIndex] + 1;
      this.recordEnd = this.recordStart + this.gatewaysService.gateways.length - 1;
    } else {
      this.recordStart = 0;
      this.recordEnd = 0;
    }
  }

  // getGatewaysList() {
  //   return this.gatewaysService.gateways;
  // }

  // getNumberGateways() {
  //   return this.gatewaysService.gateways.length;
  // }

  showPopup(name: string) {
    this.popup.name = name;
    // document.body.style.overflow = 'hidden';
  }

  closePopup() {
    this.popup.name = '';
    document.body.style.overflow = 'auto';
  }

  selectGateway(gatewayIndex: number, isSelected: boolean) {
    console.log(`gatewayIndex ${gatewayIndex} isSelected ${isSelected}`);

    if (isSelected) this.selected.number++;
    else this.selected.number--;

    this.popup.changeState.type = '';
    this.popup.changeState.devices = [];
    if (this.selected.number > 0) {
      for (let i = 0; i < this.gatewaysService.gateways.length; i++) {
        if (this.gatewaysService.gateways[i].isSelected) {
          if (this.selected.number == 1) this.selected.index = i;
          if (this.popup.changeState.type == '') {
            this.popup.changeState.type = this.gatewaysService.gateways[i].deviceType;
            if (this.gatewaysService.gateways[i].deviceType == DEVICE_TYPE.relay) {
              if (this.selected.number == 1) this.popup.changeState.state = this.gatewaysService.gateways[i].metadata.state;
              else this.popup.changeState.state = false;
              this.popup.changeState.active = true;
              this.popup.changeState.devices.push(this.gatewaysService.gateways[i].id);
            }
          } else if(this.gatewaysService.gateways[i].deviceType == this.popup.changeState.type){
            this.popup.changeState.devices.push(this.gatewaysService.gateways[i].id);
          } else {
            this.popup.changeState.active = false;
          }
        }
      }
    } else {
      this.popup.changeState.active = false;
    }
  }

  selectAllGateway() {
    this.gatewaysService.selectAllGateway(this.isSelectedAll);

    if (this.isSelectedAll) this.selected.number = this.pageSize;
    else this.selected.number = 0;
  }

  changePageNumber(pageIdx: number) {
    this.pageIndex = pageIdx;
    this.getGateways();
  }

  nextPage(numberPages: number) {
    let nubmerDevices = this.gatewaysService.numberGateways;
    if (nubmerDevices < (this.pageIndexs[0] + numberPages)*this.pageSize) return;
    if (this.pageIndexs[0] + numberPages < 0) return;
    if (numberPages == 0 ) {
      numberPages = Math.floor(nubmerDevices/this.pageSize);
      numberPages = Math.floor(numberPages/3)*3;

      this.pageIndexs[0] = numberPages;
      this.pageIndexs[1] = numberPages + 1;
      this.pageIndexs[2] = numberPages + 2;
    } else if (numberPages == -1) {
      this.pageIndexs = [0, 1, 2];
    } 
    else {
      this.pageIndexs[0] += numberPages;
      this.pageIndexs[1] += numberPages;
      this.pageIndexs[2] += numberPages;
    }

    this.getGateways();
  }

  changePageSize(pageIdx: number) {
    switch (pageIdx) {
      case 0:
        this.pageSize = 5;
        break;
      case 2:
        this.pageSize = 20;
        break;
      case 3:
        this.pageSize = 30;
        break;
      case 4:
        this.pageSize = 50;
        break;
      case 5:
        this.pageSize = 100;
        break;
      case 1:
      default:
        this.pageSize = 10;
        break;
    }

    this.pageIndex = 0;
    this.pageIndexs = [0, 1, 2];
    this.getGateways();
  }

  gatewayDetail(index: number) {
    let gateway = this.gatewaysService.gateways[index];
    console.log("gatewayDetail " + JSON.stringify(gateway));
  }
}