<div class="bma_header">
  <h3 class="bma_hdr_title">{{language.common.deviceLayout}}</h3>
  <div class="bma_hdr_breadcrums"> 
    <a class="bma_hdr_brc_tab" (click)="onChangeTab(tab)">{{tab}}</a> 
  </div>
</div>
<div class="bma_content">
  <div class="row">
    <div class="col-12">
      <div class="bma_cnt_portlet">
        <div class="bma_cnt_plt_header">
          <a class="bma_cnt_plt_hdr_tab" [ngClass]="{'bma_tab_active': tab===language.common.projects}"
          (click)="onChangeTab(language.common.projects)">
            <i class="bi bi-file-earmark-ppt"></i>
            <span style="padding-left: 10px;">{{language.common.projects}} (0)</span>
          </a>
          <a class="bma_cnt_plt_hdr_tab" [ngClass]="{'bma_tab_active': tab===language.common.areas}"
          (click)="onChangeTab(language.common.areas)">
            <i class="bi bi-geo"></i>
            <span style="padding-left: 10px;">{{language.common.areas}} (0)</span>
          </a>
          <a class="bma_cnt_plt_hdr_tab" [ngClass]="{'bma_tab_active': tab===language.common.devices}"
          (click)="onChangeTab(language.common.devices)">
            <i class="bi bi-life-preserver"></i>
            <span style="padding-left: 10px;">{{language.common.devices}} ({{getNumberDevices()}})</span>
          </a>
        </div>
        <div class="bma_cnt_plt_body">
          <app-devices *ngIf="tab===language.common.devices"></app-devices>
        </div>
      </div>
    </div>
  </div>
</div>
