import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';

import { SystemService } from '../../system/system.service';
import { DevicesService } from '../../system/body/device-layout/devices/devices.service';
import { DeviceDetailService } from '../../system/body/device-layout/device-detail/device-detail.service';

import { LANGUAGES } from '../../_services/languages';
import { DEVICE_TYPE, DEVICE_SETTINGS } from '../../_services/constant';

@Component({
  selector: 'app-advance-settings-popup',
  templateUrl: './advance-settings-popup.component.html',
  styleUrls: ['./advance-settings-popup.component.scss'],
})
export class AdvanceSettingsPopupComponent implements OnInit {

  @Input() device: any;
  @Output() popup = new EventEmitter();

  form: FormGroup | any;
  language = LANGUAGES.LANG;
  advanceSettings: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private systemService: SystemService,
    private devicesService: DevicesService,
    private deviceDetailService: DeviceDetailService,
  ) { }

  ngOnInit() {
    this.generateForm();
  }

  closePopup() {
    this.popup.emit();
  }

  onSelected(settingsIndex: number, settingIndex: number): void {
    this.advanceSettings[settingsIndex].content[settingIndex].isSelected = !this.advanceSettings[settingsIndex].content[settingIndex].isSelected;
    console.log(`advanceSettings[${settingsIndex}].content[${settingIndex}].isSelected ` + this.advanceSettings[settingsIndex].content[settingIndex].isSelected);
  }

  onSubmit() {
    if (!this.form.valid) return;
    let requestBody:any = {};
    for (let settings of this.advanceSettings) {
      for (let setting of settings.content) {
        if (setting.isSelected) {
          requestBody[setting.key] = setting.valueType==0? +this.form.controls[setting.key].value:this.form.controls[setting.key].value;
        }
      }
    }
    if (requestBody) {
      this.deviceDetailService.changeSettings(this.device.id, requestBody);
    }
  }

  generateForm(): void {
    switch (this.device.deviceType) {
      case DEVICE_TYPE.incubator:
        this.advanceSettings = [
          {
            title: this.language.deviceValues.temperature,
            content: [
              {
                key: DEVICE_SETTINGS.incubator.temperature,
                name: this.language.deviceValues.temperature,
                value: this.device.settings.temperature_target,
                valueType: 0,
                unit: this.language.unit.celsius,
                min: 0,
                max: 100,
                isSelected: false,
              },
              {
                key: DEVICE_SETTINGS.incubator.temperature_power_max,
                name: `${this.language.deviceValues.temperaturePower} ${this.language.deviceValues.threshold.max}`,
                value: this.device.settings.temperature_power_max,
                valueType: 0,
                unit: this.language.unit.percent,
                min: 0,
                max: 100,
                isSelected: false,
              },
              {
                key: DEVICE_SETTINGS.incubator.temperature_power_min,
                name: `${this.language.deviceValues.temperaturePower} ${this.language.deviceValues.threshold.min}`,
                value: this.device.settings.temperature_power_min,
                valueType: 0,
                unit: this.language.unit.percent,
                min: 0,
                max: 100,
                isSelected: false,
              }
            ]
          },
          {
            title: this.language.deviceValues.humidity,
            content: [
              {
                key: DEVICE_SETTINGS.incubator.humidity,
                name: this.language.deviceValues.humidity,
                value: this.device.settings.humidity_target,
                valueType: 0,
                unit: this.language.unit.percent,
                min: 0,
                max: 100,
                isSelected: false,
              },
              {
                key: DEVICE_SETTINGS.incubator.humidity_power_max,
                name: `${this.language.deviceValues.humidityPower} ${this.language.deviceValues.threshold.max}`,
                value: this.device.settings.humidity_power_max,
                valueType: 0,
                unit: this.language.unit.percent,
                min: 0,
                max: 100,
                isSelected: false,
              },
              {
                key: DEVICE_SETTINGS.incubator.humidity_power_min,
                name: `${this.language.deviceValues.humidityPower} ${this.language.deviceValues.threshold.min}`,
                value: this.device.settings.humidity_power_min,
                valueType: 0,
                unit: this.language.unit.percent,
                min: 0,
                max: 100,
                isSelected: false,
              }
            ]
          },
          {
            title: this.language.deviceValues.other,
            content: [
              {
                key: DEVICE_SETTINGS.incubator.swing_time,
                name: this.language.deviceValues.swingTime,
                value: this.device.settings.swing_time,
                valueType: 0,
                unit: this.language.unit.sec,
                min: 0,
                max: 50000,
                isSelected: false,
              },
              {
                key: DEVICE_SETTINGS.incubator.date_begin,
                name: this.language.deviceValues.dateBegin,
                value: this.device.settings.date_begin,
                valueType: 0,
                unit: this.language.unit.sec,
                min: 0,
                max: 4200000000,
                isSelected: false,
              }
            ]
          }
        ];
        break;
    }

    const form: any = {};
    for (const settings of this.advanceSettings) {
      for (const setting of settings.content) {
        form[setting.key] = [setting.value, [Validators.min(setting.min), Validators.max(setting.max)]];
      }
    }
    this.form = this.formBuilder.group(form);
  }
}
