export const AUTH = {
    username: '',
    password: '',
    token: '',
    // token: 'aYONS0e1efYh85PKgh48rdSR5yo0S4X8CiBj82HJypaahzXvNOxfYYnSsrZPxlV0',
    // token: 'T63G44REw3PTBB3dJjeJvTE6yCl3O4zHSG3ldhAmRU4BHeBvyOIHfiVFE7VQuR3q',
    alerts: [] as any,
    url: '',
    fullName: '',
    roles: [],
    clientId: '',
    logo: 'https://airi.freedomks.ml/assets/app/media/img//user/avatar.svg',
};
