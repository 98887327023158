import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {

  @Input() state: any;
  @Input() showTitle = true;
  @Input() size = 15;

  constructor() { }

  ngOnInit(): void {
    this.state.value = this.state.value || [false];
  }
}
