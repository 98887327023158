import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { LANGUAGES } from '../../_services/languages';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiselectComponent implements OnInit {

  @Input() filter: any;
  @Output() value = new EventEmitter();

  language: any = LANGUAGES.LANG;
  list: any = [];

  constructor() { }

  ngOnInit() {
    this.filter.isDrop = false;
    this.filter.isChange = false;
    this.filter.selectedName = this.filter.selectedName || [];
    this.filter.selectedValue = this.filter.selectedValue || [];
    this.filter.numberSelected = this.filter.selectedName.length;
    if (this.filter.selectedName.length > 0 && this.filter.selectedName.length === this.filter.list.length) {
      this.filter.isSelectAll = true;
    }
    else {
      this.filter.isSelectAll = false;
    }

    this.filter.nameAll = [];
    this.filter.valueAll = [];
    for (let i = 0; i < this.filter.list.length; i++) {
      this.filter.nameAll.push(this.filter.list[i].name);
      this.filter.valueAll.push(this.filter.list[i]?.value || this.filter.list[i].name);
    }
  }

  selectAll() {
    for (let i = 0; i < this.filter.list.length; i++) {
      this.filter.list[i].isSelected = this.filter.isSelectAll;
    }

    if (this.filter.isSelectAll) {
      this.filter.numberSelected = this.filter.list.length;
      this.filter.selectedName = [...this.filter.nameAll];
      this.filter.selectedValue = [...this.filter.valueAll];
    } else {
      this.filter.numberSelected = 0;
      this.filter.selectedName = [];
      this.filter.selectedValue = [];
    }

    this.filter.isChange = true;
  }

  filterName(name: any) {
    if (name.key == 'Enter') {
      console.log("filterName", name.target.value);
      this.list = [];
      for (let i = 0; i < this.filter.list.length; i++) {
        if (this.filter.nameAll[i].toLowerCase().includes(name.target.value.toLowerCase())) {
          this.list.push(this.filter.list[i]);
        }
      } 
    }
  }

  checkSelected(index: number) {
    if (this.filter.list[index].isSelected) {
      if (++this.filter.numberSelected == this.filter.list.length) this.filter.isSelectAll = true;
      if (this.filter.selectedName.indexOf(this.filter.nameAll[index]) == -1) {
        this.filter.selectedName.push(this.filter.nameAll[index]);
        this.filter.selectedValue.push(this.filter.valueAll[index]);
      }
    } else {
      if (--this.filter.numberSelected < this.filter.list.length) this.filter.isSelectAll = false;
      let idx = this.filter.selectedName.indexOf(this.filter.nameAll[index]);
      if (idx >= 0) {
        this.filter.selectedName.splice(idx, 1);
        this.filter.selectedValue.splice(idx, 1);
      }
    }

    this.filter.isChange = true;
  }

  selectDrop(isDrop: boolean) {
    if (this.filter.isDrop) {
      if (!isDrop && this.filter.isChange) {
        this.value.emit();
      }
    } 

    if (isDrop) {
      // this.filter.isChange = false;
      this.list = this.filter.list;
      this.filter.nameAll = [];
      this.filter.valueAll = [];
      for (let i = 0; i < this.filter.list.length; i++) {
        this.filter.list[i].index = i;
        this.filter.nameAll.push(this.filter.list[i].name);
        this.filter.valueAll.push(this.filter.list[i].value);
      }
      this.filter.numberSelected = this.filter.selectedName.length;
      if (this.filter.selectedName.length > 0 && this.filter.selectedName.length == this.filter.list.length) this.filter.isSelectAll = true;
      else this.filter.isSelectAll = false;
    }

    this.filter.isDrop = isDrop;
  }
}
