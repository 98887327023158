import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { ShareModule } from '../../../share.module';
import { DeviceDetailModule } from './device-detail/device-detail.module';

import {
  DeviceLayoutComponent,
  DevicesComponent,
  DeviceDetailPopupComponent,
  AdvanceSettingsPopupComponent,
  ChangeStatePopupComponent,
} from './device-layout.import';

const routes: Routes = [
  {
    path: '',
    component: DeviceLayoutComponent,
    children: [
      {
        path: '',
        component: DeviceLayoutComponent,
      },
      {
        path: 'device/:id',
        loadChildren: './device-detail/device-detail.module#DeviceDetailModule',
      },
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    RouterModule.forChild(routes),
    DeviceDetailModule,
  ],
  declarations: [
    DeviceLayoutComponent,
    DevicesComponent,
    DeviceDetailPopupComponent,
    AdvanceSettingsPopupComponent,
    ChangeStatePopupComponent,
  ]
})
export class DeviceLayoutModule { }
