export const SYSTEM = {
    filter: {},
    menu: {
        dashboard: 'dashboard',
        automation: 'automation',
        deviceLayout: 'device-layout',
        clients: 'clients',
        userManagerment: 'user-managerment',
        gateways: 'gateways',
    },
}