import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-device-detail-popup',
  templateUrl: './device-detail-popup.component.html',
  styleUrls: ['./device-detail-popup.component.scss'],
})
export class DeviceDetailPopupComponent implements OnInit {

  @Output() popup = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  closePopup() {
    this.popup.emit();
  }
}
