import { Component, Input, OnInit } from '@angular/core';
import { LANGUAGES } from '../../_services/languages';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  @Input() title = '';
  @Input() text: any;
  @Input() units: any;
  @Input() size = 15;
  @Input() require = true;

  language: any = LANGUAGES.LANG;

  constructor() { }

  ngOnInit(): void {
    this.text.value = typeof this.text.value === 'string' ? this.text.value : '';
    this.require = typeof this.require === 'boolean' ? this.require : true;
  }
}
