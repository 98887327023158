import { Component, OnInit } from '@angular/core';

import { LANGUAGES } from '../../../_services/languages';

import { GatewaysService } from './gateways/gateways.service';

@Component({
  selector: 'app-gateway-layout',
  templateUrl: './gateway-layout.component.html',
  styleUrls: ['./gateway-layout.component.scss']
})
export class GatewayLayoutComponent implements OnInit {

  language: any = LANGUAGES.LANG;
  tab = 'Gateways';

  constructor(
    private gatewaysService: GatewaysService,
  ) { }

  ngOnInit() {
    this.language = LANGUAGES.LANG;
  }

  onChangeTab(tab: string) {

  }

  getNumberGateways() {
    return this.gatewaysService.numberGateways;
  }
}
