import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { LANGUAGES } from '../../_services/languages';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss'],
})
export class SelectionComponent implements OnInit {
  @Input() selection: any = {};
  @Input() selected: any = {};
  @Input() unSelection = false;
  @Input() share = false;
  @Input() name = '';
  @Input() searchName = true;
  @Output() event = new EventEmitter();

  language: any = LANGUAGES.LANG;
  isDrop = false;
  list: any = [];

  constructor() { }

  ngOnInit(): void {
    this.selected.list = this.selected.list || [];
    this.selected.names = this.selected.names || [];
    this.selected.values = this.selected.values || [];
  }

  filterName(name: any): void {
    if (name.key === 'Enter') {
      this.list = [];
      for (const selection of this.selection.list) {
        if (selection.name.toLowerCase().includes(name.target.value.toLowerCase())) {
          this.list.push(selection);
        }
      }
    }
  }

  changeSelection(index: number): void {
    this.isDrop = !this.isDrop;
    let selected = {...this.selection.list[index]};
    selected = JSON.parse(JSON.stringify(selected));

    if (this.share) { // set name for share list
      const idx = this.selected.names.indexOf(this.name);
      if (idx >= 0) {
        if (selected.name) {
          this.name = selected.name;
          this.selected.list[idx] = selected;
          this.selected.names[idx] = selected.name;
          this.selected.values[idx] = selected.value || selected.name;
        } else if (this.unSelection) {
          this.selected.list.splice(idx, 1);
          this.selected.names.splice(idx, 1);
          this.selected.values.splice(idx, 1);
          this.selection.disable = false;
        }
      } else {
        this.selected.list.push(selected);
        this.selected.names.push(selected.name);
        this.selected.values.push(selected.value || selected.name);

        if (this.selected.list.length === this.selection.list.length) {
          this.selection.disable = true;
        }
      }
      if (this.name) {
        this.name = selected.name;
      }
    } else {
      if (index >= 0) {
        this.selected.list = [selected];
        this.selected.names = [selected.name];
        this.selected.values = [selected.value || selected.name];
      } else if (this.unSelection) {
        this.selected.list = [];
        this.selected.names = [];
        this.selected.values = [];
      }
    }
    this.event.emit(this.selected);
  }

  selectDrop(): void {
    this.isDrop = !this.isDrop;
    if (this.isDrop) {
      this.list = this.selection.list || [];
      if (!this.selection.list || this.selection.list.length === 0) { return; }
      for (let i = 0; i < this.selection.list.length; i++) {
        this.selection.list[i].index = i;
      }
    }
  }
}
