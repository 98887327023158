import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { APIService } from '../../../../_services/api';
import { SystemService } from "../../../system.service";

import { LANGUAGES } from '../../../../_services/languages';
import { API } from '../../../../_services/api'; 
import { AUTH } from '../../../../_services/local.storage';

@Injectable({
  providedIn: 'root'
})
export class GatewaysService {
  language: any = LANGUAGES.LANG;
  numberGateways = 0;
  gateways: any[] = [];
  httpOptions = {
    headers: new HttpHeaders(
      { 
        'Content-Type': 'application/json',
        'Authorization': AUTH.token,
      }
    ),
    params: new HttpParams(),
  };

  constructor(
    private datePipe: DatePipe,
    private http: HttpClient,
    private apiService: APIService,
    private systemService: SystemService,
  ) { 
  }

  getEventStreamGateway(){
    this.systemService.getEventStreamGateway().subscribe(gateway => {
      if (gateway && gateway.id) {
        for (let i = 0; i < this.gateways.length; i++) {
          if (gateway.id == this.gateways[i].id) {
            gateway.isSelected = this.gateways[i].isSelected;
            this.gateways[i] = this.enrichGatewayInfo(gateway);
          }
        }
      }
    });
  }

  async getGateways(filter: string): Promise<any> {
    const url = API.rootAPI + API.gateways;
    return await this.apiService.apiGetAsync(url, 'filter', filter);
  }

  updateGateways(gateways: any[]) {
    this.gateways = gateways;
    for (let i = 0; i < this.gateways.length; i++) {
      this.gateways[i] = this.enrichGatewayInfo(this.gateways[i]);
    }
  }

  async countGateways(where: string) {
    let url = API.rootAPI + API.numberGateways;
    this.numberGateways = JSON.parse(await this.apiService.apiGetAsync(url, 'where', where)).data.count;
  }

  changeState(data: any) {
    let httpOptions = {...this.httpOptions};
    this.http.patch(API.rootAPI + API.changeState, data, httpOptions).subscribe(
      (response: any) => {
        console.log('changeState ' + JSON.stringify(response));
      },
      (error) => {
        console.log(error);
        this.systemService.apiCheckError(error);
      }
    );
  }

  getGatewaysList() {
    return this.gateways;
  }

  getNumberGateways() {
    return this.gateways.length;
  }

  getGatewayInfo(gatewayId: string) {
    for (let i = 0; i < this.gateways.length; i++) {
      if (gatewayId == this.gateways[i].id) {
        return this.gateways[i];
      }
    }

    return null;
  }

  enrichGatewayInfo(gateway: any) {
    
    return gateway;
  }

  selectAllGateway(isSelected: boolean) {
    for (let i = 0; i < this.gateways.length; i++) {
      this.gateways[i].isSelected = isSelected;
    }
  }
}
