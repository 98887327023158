import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { APIService } from '../../../../_services/api';
import { SystemService } from '../../../system.service';

import { LANGUAGES } from '../../../../_services/languages';
import { API } from '../../../../_services/api';
import { AUTH } from '../../../../_services/local.storage';
import { DEVICE_TYPE } from '../../../../_services/constant';
import { DEVICE_LAYOUT, DeviceInfo } from '../device-layout.model';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  language: any = LANGUAGES.LANG;
  numberDevices = 0;
  devices: DeviceInfo[] = [];

  constructor(
    private datePipe: DatePipe,
    private apiService: APIService,
    private systemService: SystemService,
  ) { }

  getEventStreamDevice(): void {
    this.systemService.getEventStreamDevice().subscribe(device => {
      if (device && device.id) {
        for (let i = 0; i < this.devices.length; i++) {
          if (device.id === this.devices[i].id) {
            device.isSelected = this.devices[i].isSelected;
            this.devices[i] = this.enrichDeviceInfo(device);
          }
        }
      }
    });
  }

  async getDevices(filter: string): Promise<any> {
    const url = API.rootAPI + API.devices;
    return await this.apiService.apiGetAsync(url, 'filter', filter);

    // this.language = LANGUAGES.language;
    // let httpOptions = {...this.httpOptions};
    // httpOptions.params = new HttpParams().set('filter', `${filter}`);
    // this.http.get<DeviceInfo[]>(API.rootAPI + API.devices, httpOptions).subscribe(
    //   (response: any) => {
    //     this.devices = response.data;

    //     for (let i = 0; i < this.devices.length; i++) {
    //       this.devices[i] = this.enrichDeviceInfo(this.devices[i]);
    //     }

    //     // console.log(`deviceList ${JSON.stringify(DEVICE_LAYOUT.deviceList)}`);
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.bodyService.apiCheckError(error);
    //   })

    // httpOptions.params = new HttpParams().set('where', `${filter}`);
    // this.http.get(API.rootAPI + API.numberDevices, httpOptions).subscribe(
    //   (response: any) => {
    //     this.numberDevices = response.count;

    //     // console.log(`number Device ${this.numberDevices}`);
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.bodyService.apiCheckError(error);
    //   })
  }

  updateDevices(devices: any[]): void {
    this.devices = devices;
    for (let i = 0; i < this.devices.length; i++) {
      this.devices[i] = this.enrichDeviceInfo(this.devices[i]);
    }
  }

  async countDevices(where: string): Promise<void> {
    const url = API.rootAPI + API.numberDevices;
    this.numberDevices = JSON.parse(await this.apiService.apiGetAsync(url, 'where', where)).data.count;
  }

  changeState(data: any): void {
    const url = API.rootAPI + API.changeState;
    this.apiService.apiPatch(url, data).subscribe(
      (response: any) => {
        console.log('changeState ' + JSON.stringify(response));
      },
      (error: any) => {
        console.log(error);
        this.systemService.apiCheckError(error);
      }
    );
  }

  getDevicesList() {
    return this.devices;
  }

  getNumberDevices() {
    return this.numberDevices;
  }

  getDeviceInfo(deviceId: string) {
    for (let i = 0; i < this.devices.length; i++) {
      if (deviceId === this.devices[i].id) {
        return this.devices[i];
      }
    }

    return null;
  }

  enrichDeviceInfo(device: any): any {
    switch (device.deviceType) {
      case DEVICE_TYPE.repeater:
        device.type = this.language.deviceType.repeater || '';
        device.data = [
          { name: 'Error', value: 'Code_' + device.metadata.error } || '',
        ];
        device.setting = [
          { name: 'hop', value: device.settings.hop_number } || '',
          { name: 'Lane', value: device.settings.lane_id } || '',
        ];
        break;
      case DEVICE_TYPE.incubator:
        device.type = this.language.deviceType.incubator || '';
        console.log('device.metadata', device.metadata);
        device.values = [
          { name: 'Error', value: 'Code_' + device.metadata.error} || '',
          { name: 'Temperature (°C)', value: device.metadata.temperature} || '',
          { name: 'Temperature Power (%)', value: device.metadata.temperature_power} || '',
          { name: 'Humidity (%)', value: device.metadata.humidity } || '',
          { name: 'Humidity Power (%)', value: device.metadata.humidity_power} || '',
          { name: 'Swing Timer', value: this.datePipe.transform(new Date(device.metadata.swing_timer*1000 || 0), 'HH:mm:ss', '+0') || ''}
        ];
        device.value = `${device.metadata.temperature || 'N/A'} °C | \
                        ${device.metadata.humidity || 'N/A'} % | \
                        ${this.datePipe.transform(new Date(device.metadata.swing_timer * 1000 || 0), 'HH:mm:ss', '+0')}`;
        device.setting = [
          { name: 'Temperature (°C)', value: device.settings.temperature_target}  || '',
          { name: 'Temperature Power Max (%)', value: device.settings.temperature_power_max}  || '',
          { name: 'Temperature Power Min (%)', value: device.settings.temperature_power_min}  || '',
          { name: 'Humidity (%)', value: device.settings.humidity_target}  || '',
          { name: 'Humidity Power Max (%)', value: device.settings.humidity_power_max}  || '',
          { name: 'Humidity Power Min (%)', value: device.settings.humidity_power_min}  || '',
          { name: 'Swing Time', value: this.datePipe.transform(new Date(device.settings.swing_time*1000), 'HH:mm:ss', '+0') || ''},
          { name: 'Date Begin', value: this.datePipe.transform(new Date(device.settings.date_begin*1000), 'EEEE dd/MM/yyyy HH:mm:ss') || ''}
        ];
        break;
      case DEVICE_TYPE.relay:
        device.type = this.language.deviceType.relay || '';
        device.values = [
          { name: 'Error', value: 'Code_' + device.metadata.error } || '',
          {name: 'State', value: device.metadata.state ? 'ON' : 'OFF'} || '',
        ];
        device.value = device.metadata.state ? 'ON' : 'OFF';

        if (device.shadow?.metadata?.isChanging !== undefined) {
          device.valueTip = `${device.shadow.metadata.isManual ? 'Manual ' : 'Auto'} \
          ${device.shadow.metadata.isChanging ? 'changing to ' : 'changed to '} \
          ${device.shadow.metadata.state ? 'ON' : 'OFF'}`;
        }
        device.setting = [
          { name: 'Power Max (W)', value: device.settings.temperature_power_max } || '',
        ];
        break;
        case DEVICE_TYPE.fan:
          device.type = this.language.deviceType.fan || '';
          device.values = [
            { name: 'Error', value: 'Code_' + device.metadata.error } || '',
          ];
          device.value = 'N/A';
          device.setting = [];
          break;
      case DEVICE_TYPE.tempHumi:
        device.type = this.language.deviceType.tempHumi || '';
        console.log('device.metadata', device.metadata);
        device.values = [
          { name: 'Error', value: 'Code_' + device.metadata.error} || '',
          { name: 'Temperature (°C)', value: device.metadata.temperature} || '',
          { name: 'Humidity (%)', value: device.metadata.humidity } || '',
        ];
        device.value = `${device.metadata.temperature || 'N/A'} °C | \
                        ${device.metadata.humidity || 'N/A'} %`;
        device.setting = [];
        break;
      default:
        device.type = '';
        device.values = [];
        break;
    }
    return device;
  }

  selectAllDevice(isSelected: boolean): void {
    for (const devices of this.devices) {
      devices.isSelected = isSelected;
    }
  }
}
