import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { v4 as uuidv4 } from 'uuid';

import { API, APIService } from '../_services/api';
import { LANGUAGES } from '../_services/languages';
import { AUTH } from '../_services/local.storage';

import { OneSignalService } from '../_services/one-signal';
import { SystemService } from './system.service';
import { SYSTEM } from './system.model';
import { helpers } from 'chart.js';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {

  system = SYSTEM;

  constructor(
    private router: Router,
    private apiService: APIService,
    private onesignalService: OneSignalService,
    private systemService: SystemService,
  ) { }

  async ngOnInit(): Promise<void> {
    // let accessToken = localStorage.getItem('accessToken');
    // if(accessToken){
    //   AUTH.token =  accessToken;
    //   console.log("SYSTEM localStorage accessToken " + accessToken);
    // }

    // if (!AUTH.token) {
    //   this.router.navigate(['/login']);
    // }

    LANGUAGES.LANG = LANGUAGES.VI;
    const me: any = await this.getMe();
    if (me.notify.web.id) {
      this.onesignalService.onInit(me.notify.web.id);
    }

    this.getEventSource();
    this.systemService.getRabbitWebStomp({
      topics: [
        `/topic/stream.reply.${AUTH.clientId ? AUTH.clientId : '*'}`,
      ],
      headers: {},
      queueName: 'web.adm_' + uuidv4(),
    });
  }

  async getMe(): Promise<void> {
    const url = API.rootAPI + API.getMe;
    const me = JSON.parse(await this.apiService.apiGetAsync(url, 'filter', '{}')).data;
    AUTH.fullName = me.fullName;
    AUTH.roles = me.roles;
    AUTH.clientId = me.clientId;
    AUTH.logo = me.logo ? me.logo : 'https://airi.freedomks.ml/assets/app/media/img//user/avatar.svg';

    return me;
  }

  getEventSource(): void {
    if (typeof (EventSource) !== 'undefined') {
      const httpOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: AUTH.token,
          }
        ),
      };

      const url = `${API.rootAPI}${API.changeStream}?access_token=${AUTH.token}`;
      const eventSource = new EventSource(url);
      eventSource.addEventListener('data', (msg: any) => {
        // const msgJson = JSON.parse(msg.data);
        // console.log(`change-stream ${msg.data}`);
      });
    } else {
      console.log('event source error');
    }
  }
}
