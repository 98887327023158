<div class="header" (window:resize)="onResize($event)">
  <div class="header_topbar">
    <!-- <div class="header_stack-general" 
        [ngClass]="{'header_full': getMenuClose(), 'header_collapse': !getMenuClose()&&!isMenuFull}"> -->
    <div class="hdr_tb_general">
      <div class="header_toggler" (click)="toggleMenu()">
        <i class="bi bi-list"></i>
      </div>
      <div class="header_title">AIRI</div>
    </div>
    <div class="hdr_user_info" clickOutside (clickOutside)="showUserInfo=false"
      [ngStyle]="{'background-image': 'url(' + auth.logo + ')'}" (click)="showUserInfo=!showUserInfo">
      <div *ngIf="showUserInfo" class="hdr_usr_dropmemu">
        <div class="hdr_usr_common">
          <div class="hdr_usr_avatar" [ngStyle]="{'background-image': 'url(' + auth.logo + ')'}">
          </div>
          <div>
            <div class="hdr_usr_name">{{auth.fullName}}</div>
            <div class="hdr_usr_role">{{auth.roles}}</div>
          </div>
        </div>
        <div class="hdr_usr_profile">
          <i class="bi bi-person-badge"></i>
          <span style="padding-left: 10px;">My profile</span>
        </div>
        <div class="hdr_usr_password">
          <i class="bi bi-key"></i>
          <span style="padding-left: 10px;">Change password</span>
        </div>
        <div class="hdr_usr_logout" (click)="onLogout()">
          Logout
        </div>
      </div>
    </div>
  </div>
</div>
