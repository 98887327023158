import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';

import { DeviceDetailComponent } from './device-detail.component';

const routes: Routes = [
    {
        path: "device/:id",
        component: DeviceDetailComponent,
    },
];

@NgModule({
    imports: [
        CommonModule,
        ChartsModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        DeviceDetailComponent,
    ]
})
export class DeviceDetailModule { }
