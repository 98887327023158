<div class="bmenu" [ngClass]="{'bmenu_collapse': !getMenuFull()}"
    *ngIf="!getMenuClose()||getMenuFull()">
  <!-- <div *ngIf="getMenuToogle()" class="bme_btn_close" (click)="toggleMenu()">
    <i class="bi bi-x"></i>
   </div> -->
  <div class="bme_list">
    <a class="bme_item" [ngClass]="{'bme_item_active': menu===system.menu.dashboard}"
      (click)="onChangeMenu(system.menu.dashboard)" routerLink={{system.menu.dashboard}}>
      <i class="bi bi-speedometer2"></i>
      <span style="padding-left: 10px;" *ngIf="getMenuFull()">{{language.common.dashboard}}</span>
    </a>
    <a class="bme_item" [ngClass]="{'bme_item_active': menu===system.menu.automation}"
      (click)="onChangeMenu(system.menu.automation)" routerLink={{system.menu.automation}}>
      <i class="bi bi-sliders"></i>
      <span style="padding-left: 10px;" *ngIf="getMenuFull()">{{language.common.automation}}</span>
    </a>
    <a class="bme_item" [ngClass]="{'bme_item_active': menu===system.menu.deviceLayout}"
      (click)="onChangeMenu(system.menu.deviceLayout)" routerLink={{system.menu.deviceLayout}}>
      <i class="bi bi-columns-gap"></i>
      <span style="padding-left: 10px;" *ngIf="getMenuFull()">{{language.common.deviceLayout}}</span>
    </a>
    <a class="bme_item" [ngClass]="{'bme_item_active': menu===system.menu.clients}"
      (click)="onChangeMenu(system.menu.clients)" routerLink={{system.menu.clients}}>
      <i class="bi bi-textarea"></i>
      <span style="padding-left: 10px;" *ngIf="getMenuFull()">{{language.common.clients}}</span>
    </a>
    <a class="bme_item" [ngClass]="{'bme_item_active': menu===system.menu.userManagerment}"
      (click)="onChangeMenu(system.menu.userManagerment)" routerLink={{system.menu.userManagerment}}>
      <i class="bi bi-shield"></i>
      <span style="padding-left: 10px;" *ngIf="getMenuFull()">{{language.common.userManagement}}</span>
    </a>
    <a class="bme_item" [ngClass]="{'bme_item_active': menu===system.menu.gateways}"
      (click)="onChangeMenu(system.menu.gateways)" routerLink={{system.menu.gateways}}>
      <i class="bi bi-wifi"></i>
      <span style="padding-left: 10px;" *ngIf="getMenuFull()">{{language.common.gateways}}</span>
    </a>
  </div>
</div>
<div class="bmain" [ngClass]="{'bmain_full': getMenuClose(), 'bmain_extend': !getMenuClose()&&!getMenuFull()}">
  <router-outlet></router-outlet>
</div>