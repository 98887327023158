import { Component, OnInit } from '@angular/core';

import { SystemService} from '../system.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private systemService: SystemService,
  ) { }

  ngOnInit() {
  }

  getViewMenu() {
    return this.systemService.isMenuFull;
  }
}
