import { Injectable } from '@angular/core';

import { APIService } from '../../../../_services/api';
import { LANGUAGES } from '../../../../_services/languages';
import { API } from '../../../../_services/api';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  language: any = LANGUAGES.LANG;
  numberActions = 0;
  actions: any = [];

constructor(
  private apiService: APIService,
) { }

  async getActions(filter: string): Promise<any> {
    const url = API.rootAPI + API.actions;
    return await this.apiService.apiGetAsync(url, 'filter', filter);
  }

  updateActions(actions: any[]): void {
    this.actions = actions;
    for (let i = 0; i < this.actions.length; i++) {
      this.actions[i] = this.enrichActionInfo(this.actions[i]);
    }
  }

  async countActions(where: string): Promise<any> {
    const url = API.rootAPI + API.numberActions;
    this.numberActions = JSON.parse(await this.apiService.apiGetAsync(url, 'where', where)).data.count;
  }

  enrichActionInfo(action: any): any {
    return action;
  }

  selectAllActions(isSelected: boolean): void {
    for (const action of this.actions) {
      action.isSelected = isSelected;
    }
  }
}
