import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SystemComponent } from './system.component';
import { SYSTEM } from './system.model';
import { 
  AuthGuard, 
} from './system.import'; 

const routes: Routes = [
  {
    path: '',
    component: SystemComponent,
    canActivate: [AuthGuard],
    "children": [
      {
        "path": SYSTEM.menu.dashboard,
        "loadChildren": "./body/dashboard/dashboard.module#DashboardModule",
      },
      {
        "path": SYSTEM.menu.automation,
        "loadChildren": "./body/automation/automation.module#AutomationModule",
      },
      {
        "path": SYSTEM.menu.deviceLayout,
        "loadChildren": "./body/device-layout/device-layout.module#DeviceLayoutModule",
      },
      {
        "path": SYSTEM.menu.clients,
        "loadChildren": "./body/clients/clients.module#ClientsModule",
      },
      {
        "path": SYSTEM.menu.userManagerment,
        "loadChildren": "./body/userManagerment/userManagerment.module#UserManagermentModule",
      },
      {
        "path": SYSTEM.menu.gateways,
        "loadChildren": "./body/gateway-layout/gateway-layout.module#GatewayLayoutModule",
      },
      {
        "path": "",
        "redirectTo": SYSTEM.menu.dashboard,
        "pathMatch": "full"
      }
    ]
  },  
  // { path: SYSTEM.menuList.dashboard, component: DashboardComponent},
  // { path: SYSTEM.menuList.deviceLayout, component: DeviceLayoutComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    // PermissionDataResolver,
    // PublicDataResolver
  ],
   declarations: [	
   ]
})
export class SystemRoutingModule { }
