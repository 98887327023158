import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LANGUAGES } from '../../_services/languages';

import { SYSTEM } from '../system.model';
import { SystemService} from '../system.service';
 
@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {

  language: any = LANGUAGES.LANG;
  system = SYSTEM;
  menu = SYSTEM.menu.dashboard;
  
  constructor(
    private router: Router,
    private systemService: SystemService,
  ) { 
  }

  ngOnInit() {
    this.menu = this.router.url.split('/')[1];
    this.language = LANGUAGES.LANG;
  }

  getMenuClose() {
    return this.systemService.isMenuClose;
  }

  toggleMenu() {
    this.systemService.toggleMenu();
  }

  getMenuFull() {
    return this.systemService.isMenuFull;
  }

  getMenuToogle() {
    return this.systemService.isMenuToggle;
  }

  onChangeMenu(menu: string) {
    this.menu = menu;
  }

  getAmqpConnection() {
    // var connection = new Amqp.Connection("amqp://gateway_dev:gateway_dev_pass@192.168.2.209:5672/dev");
  //   connection.on('open_connection', function() {
  //     console.log("getAmqpConnection connected");
  //   });
  //   connection.on('error_connection', function(err) {
  //     console.log("getAmqpConnection error " + err);
  //   });

  //   // var exchange = connection.declareExchange("amq.topic", "topic", { durable: true });
  //   // var queue = connection.declareQueue("frontend.toan", { exclusive: true });
  //   // queue.bind(exchange);
  //   // queue.activateConsumer((message) => {
  //   //     console.log("Message received: " + message.getContent());
  //   // });
    
  //   // it is possible that the following message is not received because
  //   // it can be sent before the queue, binding or consumer exist
  //   // var msg = new Amqp.Message("Test");
  //   // exchange.send(msg);
    
  //   connection.completeConfiguration().then(() => {
  //       // the following message will be received because
  //       // everything you defined earlier for this connection now exists
  //       // var msg2 = new Amqp.Message("Test2");
  //       // exchange.send(msg2);
  //   });
  }
}
