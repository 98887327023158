import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';

import { AUTH } from '../../_services/local.storage';
import { LANGUAGES } from '../../_services/languages';
import { API, APIService } from '../../_services/api';

import {
  INPUT_MODE,
  ACTION_SCRIPT_PARAMETER,
  ACTION_SCRIPT_EFFECT,
  ACTION_CONDITION_PARAMETER,
  ACTION_CONDITION_DOIF,
  ACTION_SCHEDULE_REPEAT,
  DEVICE_TYPE,
} from '../../_services/constant';

import { ClientsService } from '../../system/body/clients/clients.service';
import { GatewaysService } from '../../system/body/gateway-layout/gateways/gateways.service';
import { DevicesService } from '../../system/body/device-layout/devices/devices.service';

@Component({
  selector: 'app-action-popup',
  templateUrl: './action-popup.component.html',
  styleUrls: ['./action-popup.component.scss'],
})
export class ActionPopupComponent implements OnInit {

  @Input() actionOld: any;
  @Output() popup = new EventEmitter();

  form: FormGroup | any;
  auth = AUTH;
  language: any = LANGUAGES.LANG;
  inputMode: any = {...INPUT_MODE};
  deviceType: any = {...DEVICE_TYPE};
  actionScriptParameter: any = {...ACTION_SCRIPT_PARAMETER};
  actionScriptEffect: any = {...ACTION_SCRIPT_EFFECT};
  actionConditionParameter: any = {...ACTION_CONDITION_PARAMETER};
  actionNew: any = {};

  selectedTemplate = {
    list: [],
    names: [],
    values: [],
  };

  action: any = {
    name: {
      selection: {
        title: this.language.itemInfo.name,
      },
      selected: {
        ...this.selectedTemplate,
        list: [{}],
      },
    },
    client: {
      selection: {
        title: this.language.common.client,
      },
      selected: {...this.selectedTemplate},
    },
    gateway: {
      selection: {
        title: this.language.common.gateway,
      },
      selected: {...this.selectedTemplate},
    },
    scriptType: {
      selection: {
        title: this.language.itemInfo.type,
      },
      selected: {...this.selectedTemplate},
    },
    scriptDevice: {
      selection: {
        title: this.language.common.device,
      },
      selected: {...this.selectedTemplate},
    },
    scriptParameter: {
      selection: {
        title: this.language.itemInfo.parameter,
        list: [],
      },
      selected: {...this.selectedTemplate},
    },
    scriptEffect: {
      selection: {
        title: this.language.automation.effect,
      },
      selected: {...this.selectedTemplate},
    },
    scriptValue: {
      selection: {
        title: this.language.itemInfo.value,
      },
      selected: {...this.selectedTemplate},
    },
    conditionType: {
      selection: {
        title: this.language.itemInfo.type,
      },
      selected: {...this.selectedTemplate},
    },
    conditionDevice: {
      selection: {
        title: this.language.common.device,
      },
      selected: {...this.selectedTemplate},
    },
    conditionParameter: {
      selection: {
        title: this.language.itemInfo.parameter,
        list: [],
      },
      selected: {...this.selectedTemplate},
    },
    conditionDoIf: {
      selection: {
        title: this.language.automation.doIf,
      },
      selected: {...this.selectedTemplate},
    },
    conditionValue: {
      selection: {
        title: this.language.itemInfo.value,
      },
      selected: {...this.selectedTemplate},
    },
    scheduleStart: {
      selection: {
        title: this.language.automation.startTime,
      },
      selected: {...this.selectedTemplate},
    },
    scheduleEnd: {
      selection: {
        title: this.language.automation.endTime,
      },
      selected: {...this.selectedTemplate},
    },
    scheduleRepeat: {
      selection: {
        title: this.language.automation.repeat,
      },
      selected: {...this.selectedTemplate},
    },
    status: {
      selection: {
        title: this.language.itemInfo.status,
      },
      selected: {
        ...this.selectedTemplate,
        list: [{value: [true]}],
      },
    }
  };

  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private apiService: APIService,
    private clientsService: ClientsService,
    private gatewaysService: GatewaysService,
    private devicesService: DevicesService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.generateForm();
    await this.getSelections();
  }

  async getSelections(): Promise<void> {
    for (const selection in this.action) {
      if (this.action.hasOwnProperty(selection)) {
        if (['name', 'status'].includes(selection)) { continue; }
        this.refreshSelection(this.action[selection]);
      }
    }

    if (!this.actionOld) {
      if (!this.auth.clientId) {
        this.getSelectionClient();
      } else {
        this.getSelectionGateway();
      }
      this.getSelectionScriptType();
      this.getSelectionConditionType();
      this.getSelectionSchedule();
    } else {

    }
  }

  async getSelectionClient(): Promise<void> {
    this.action.client.selection.list = JSON.parse(await this.clientsService.getClients('{}')).data;

    for (const client of this.action.client.selection.list) {
      client.value = client.id;
    }
  }

  async getSelectionGateway(): Promise<void> {
    this.action.gateway.selection.list = [];
    const filter = `{"where": {"clientId": { "inq": ${JSON.stringify(this.action.client.selected.values)}}},"override":true}`;
    this.action.gateway.selection.list = JSON.parse(await this.gatewaysService.getGateways(filter)).data || [];

    for (const gateway of this.action.gateway.selection.list) {
      gateway.value = gateway.gatewayId;
    }

    // // tslint:disable-next-line: prefer-for-of
    // for (let i = 0; i < this.action.gateway.selection.list.length; i++) {
    //   this.action.gateway.list[i].value = this.action.gateway.list[i].gatewayId;
    // }
  }

  async getSelectionScriptType(): Promise<void> {
    this.action.scriptType.selection.list = [];
    for (const type in this.actionScriptParameter) {
      if (this.actionScriptParameter.hasOwnProperty(type)) {
        this.action.scriptType.selection.list.push({
          key: type,
          name: this.language.deviceType[type],
          value: this.deviceType[type],
        });
      }
    }
  }

  async getSelectionScriptDevice(): Promise<void> {
    this.action.scriptDevice.selection.list = [];
    const filter = {
      where: {
        clientId: {inq: this.action.client.selected.values},
        gatewayId: {inq: this.action.gateway.selected.values},
        deviceType: {inq: this.action.scriptType.selected.values}
      },
      override: true
    };

    this.action.scriptDevice.selection.list = JSON.parse(await this.devicesService.getDevices(JSON.stringify(filter))).data;
    for (const device of this.action.scriptDevice.selection.list) {
      device.value = device.id;
    }
  }

  async getSelectionScriptParameter(): Promise<void> {
    this.action.scriptParameter.selection.list = [];
    for (const parameter of this.actionScriptParameter[this.action.scriptType.selected.list[0].key]) {
      parameter.name = this.language.deviceValues[parameter.key] || parameter.key;
      this.action.scriptParameter.selection.list.push(parameter);
    }
  }

  async getSelectionScriptEffect(): Promise<void> {
    this.action.scriptEffect.selection.list = [
      { name: this.language.automation.changeTo, value: ACTION_SCRIPT_EFFECT.changeTo },
      { name: this.language.automation.adjust, value: ACTION_SCRIPT_EFFECT.adjust },
      { name: this.language.automation.formula, value: ACTION_SCRIPT_EFFECT.formula },
    ];

    for (const item of this.action.scriptParameter.selected.list) {
      if (!item.effect) {
        this.action.scriptEffect.invalid = true;
        return;
      }
    }
    this.action.scriptEffect.invalid = false;
  }

  async getSelectionScriptValue(): Promise<void> {
    this.action.scriptValue.invalid = false;
    for (const item of this.action.scriptParameter.selected.list) {
      if (item.type === 'boolean') {
        item.value = item.value || this.action.scriptParameter.selection.list[item.index].value || [false];
      } else if (item.type === 'number') {
        item.min = this.action.scriptParameter.selection.list[item.index].min;
        item.max = this.action.scriptParameter.selection.list[item.index].max;
        if (item.effect + '' === ACTION_SCRIPT_EFFECT.adjust) {
          const range = item.max - item.min;
          item.max = range;
          item.min = - range;
        }
        item.center = Math.floor((item.max + item.min) / 2);
        item.value = item.value || this.action.scriptParameter.selection.list[item.index].value || [item.center];
      } else {
        item.value = item.value || this.action.scriptParameter.selection.list[item.index].value || '';
      }

      if (item.effect + '' === ACTION_SCRIPT_EFFECT.formula) {
        if (typeof item.value !== 'string') {
          item.value = '';
        }
      }
    }
  }

  async getSelectionConditionType(): Promise<void> {
    this.action.conditionType.selection.list = [];
    for (const type in this.actionConditionParameter) {
      if (this.actionConditionParameter.hasOwnProperty(type)) {
        this.action.conditionType.selection.list.push({
          key: type,
          name: this.language.deviceType[type],
          value: this.deviceType[type],
        });
      }
    }
  }

  async getSelectionConditionDevice(): Promise<void> {
    this.action.conditionDevice.selection.list = [];
    const filter = {
      where: {
        clientId: {inq: this.action.client.selected.values},
        gatewayId: {inq: this.action.gateway.selected.values},
        deviceType: {inq: this.action.conditionType.selected.values}
      },
      override: true
    };

    this.action.conditionDevice.selection.list = JSON.parse(await this.devicesService.getDevices(JSON.stringify(filter))).data;
    for (const device of this.action.conditionDevice.selection.list) {
      device.value = device.id;
    }
  }

  async getSelectionConditionParameter(): Promise<void> {
    this.action.conditionParameter.selection.list = [];
    for (const parameter of this.actionConditionParameter[this.action.conditionType.selected.list[0].key]) {
      parameter.name = this.language.deviceValues[parameter.key] || parameter.key;
      this.action.conditionParameter.selection.list.push(parameter);
    }
  }

  async getSelectionConditionDoIf(): Promise<void> {
    this.action.conditionDoIf.selection.list = [
      { name: this.language.automation.gt, value: ACTION_CONDITION_DOIF.gt },
      { name: this.language.automation.lt, value: ACTION_CONDITION_DOIF.lt },
      { name: this.language.automation.eq, value: ACTION_CONDITION_DOIF.eq },
      { name: this.language.automation.diff, value: ACTION_CONDITION_DOIF.diff },
    ];
    for (const item of this.action.conditionParameter.selected.list) {
      if (!item.doif) {
        this.action.conditionDoIf.invalid = true;
        return;
      }
    }
    this.action.conditionDoIf.invalid = false;
  }

  async getSelectionConditionValue(): Promise<void> {
    this.action.conditionValue.invalid = false;
    for (const item of this.action.conditionParameter.selected.list) {
      if (item.type === 'boolean') {
        item.value = item.value || this.action.conditionParameter.selection.list[item.index].value || [false];
      } else if (item.type === 'number') {
        item.min = this.action.conditionParameter.selection.list[item.index].min;
        item.max = this.action.conditionParameter.selection.list[item.index].max;
        item.center = Math.floor((item.max + item.min) / 2);
        item.value = item.value || this.action.conditionParameter.selection.list[item.index].value || [item.center];
      } else {
        item.value = item.value || this.action.conditionParameter.selection.list[item.index].value || '';
      }
    }
  }

  async getSelectionSchedule(): Promise<void> {
    this.action.scheduleStart.invalid = false;
    this.action.scheduleEnd.invalid = false;
    this.action.scheduleRepeat.invalid = false;
    this.action.scheduleStart.selected.value = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
    this.action.scheduleEnd.selected.value = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
    this.action.scheduleStart.selected.list = [{value: this.action.scheduleStart.selected.value}];
    this.action.scheduleEnd.selected.list = [{value: this.action.scheduleEnd.selected.value}];
    this.action.scheduleRepeat.selection.list = [
      { key: ACTION_SCHEDULE_REPEAT.minute, name: this.language.unit.minute, value: '0' },
      { key: ACTION_SCHEDULE_REPEAT.hour, name: this.language.unit.hour, value: '0' },
      { key: ACTION_SCHEDULE_REPEAT.day, name: this.language.unit.day, value: '0' },
      { key: ACTION_SCHEDULE_REPEAT.week, name: this.language.unit.week, value: '0' },
      { key: ACTION_SCHEDULE_REPEAT.month, name: this.language.unit.month, value: '0' },
      { key: ACTION_SCHEDULE_REPEAT.year, name: this.language.unit.year, value: '0' },
    ];
    this.action.scheduleRepeat.selected.names = [this.language.unit.day];
    this.action.scheduleRepeat.selected.list.push(this.action.scheduleRepeat.selection.list[2]);
  }

  async changeSelection(title: string, selected: any): Promise<void> {
    switch (title) {
      case this.action.client.selection.title:
        this.action.client.invalid = !this.validateSelection(this.action.client.selected.values);
        this.refreshSelection(this.action.gateway);
        this.refreshSelection(this.action.scriptType);
        this.refreshSelection(this.action.scriptParameter);
        this.refreshSelection(this.action.scriptDevice);
        this.refreshSelection(this.action.scriptEffect);
        this.refreshSelection(this.action.scriptValue);
        this.refreshSelection(this.action.conditionType);
        this.refreshSelection(this.action.conditionParameter);
        this.refreshSelection(this.action.conditionDevice);
        this.refreshSelection(this.action.conditionDoIf);
        this.refreshSelection(this.action.conditionValue);
        this.getSelectionGateway();
        break;
      case this.action.gateway.selection.title:
        this.action.gateway.invalid = !this.validateSelection(this.action.gateway.selected.values);
        this.refreshSelection(this.action.scriptType);
        this.refreshSelection(this.action.scriptParameter);
        this.refreshSelection(this.action.scriptDevice);
        this.refreshSelection(this.action.scriptEffect);
        this.refreshSelection(this.action.scriptValue);
        this.refreshSelection(this.action.conditionType);
        this.refreshSelection(this.action.conditionParameter);
        this.refreshSelection(this.action.conditionDevice);
        this.refreshSelection(this.action.conditionDoIf);
        this.refreshSelection(this.action.conditionValue);
        break;
      case this.action.scriptType.selection.title + 'script':
        this.action.scriptType.invalid = !this.validateSelection(this.action.scriptType.selected.values);
        this.refreshSelection(this.action.scriptParameter);
        this.refreshSelection(this.action.scriptDevice);
        this.refreshSelection(this.action.scriptEffect);
        this.refreshSelection(this.action.scriptValue);
        this.getSelectionScriptDevice();
        this.getSelectionScriptParameter();
        break;
      case this.action.scriptParameter.selection.title + 'script':
        this.action.scriptParameter.invalid = !this.validateSelection(this.action.scriptParameter.selected.values);
        this.getSelectionScriptEffect();
        break;
      case this.action.scriptEffect.selection.title:
        this.getSelectionScriptEffect();
        this.getSelectionScriptValue();
        break;
      case this.action.conditionType.selection.title + 'condition':
        this.action.conditionType.invalid = !this.validateSelection(this.action.conditionType.selected.values);
        this.refreshSelection(this.action.conditionDevice);
        this.refreshSelection(this.action.conditionParameter);
        this.refreshSelection(this.action.conditionDoIf);
        this.refreshSelection(this.action.conditionValue);
        if (this.action.conditionType.invalid) { return; }
        this.getSelectionConditionDevice();
        this.getSelectionConditionParameter();
        break;
      case this.action.conditionParameter.selection.title + 'condition':
        this.action.conditionParameter.invalid = !this.validateSelection(this.action.conditionParameter.selected.values);
        this.getSelectionConditionDoIf();
        break;
      case this.action.conditionDoIf.selection.title:
        this.getSelectionConditionDoIf();
        this.getSelectionConditionValue();
        break;
      case this.action.scheduleStart.selection.title:
      case this.action.scheduleEnd.selection.title:
        if (this.action.scheduleStart.selected.value > this.action.scheduleEnd.selected.value) {
          this.action.scheduleStart.selected.list = [];
          this.action.scheduleEnd.selected.list = [];
          this.action.scheduleStart.invalid = true;
          this.action.scheduleEnd.invalid = true;
        } else {
          this.action.scheduleStart.selected.list = [{value: this.action.scheduleStart.selected.value}];
          this.action.scheduleEnd.selected.list = [{value: this.action.scheduleEnd.selected.value}];
          this.action.scheduleStart.invalid = false;
          this.action.scheduleEnd.invalid = false;
        }
        break;
      default:
        break;
    }
  }

  refreshSelection(action: any): void {
    action.invalid = true;
    action.selection.disable = false;
    action.selected.list = [];
    action.selected.names = [];
    action.selected.values = [];
  }

  validateSelection(value: any): boolean {
    if (value == null || !value.length) {
      return false;
    }
    return  true;
  }

  closePopup(): void {
    this.popup.emit();
  }

  async onSubmit(): Promise<void> {
    let actionInvalid = false;
    for (const key in this.action) {
      if (this.action.hasOwnProperty(key)) {
        this.action[key].invalid = false;
        if (['scriptEffect', 'scriptValue', 'conditionType', 'conditionDoIf', 'conditionValue'].includes(key)) {
          continue;
        }
        if (!this.action.conditionType.selected.values.length && ['conditionParameter', 'conditionDevice'].includes(key)) {
          continue;
        }
        if (!this.action[key].selected.list.length) {
          actionInvalid = true;
          this.action[key].invalid = true;
          console.log(key, 'length invalid');
        } else {
          for (const selected of this.action[key].selected.list) {
            if (key === 'scriptParameter') {
              if (!this.validateSelection(selected.effect)) {
                actionInvalid = true;
                this.action.scriptEffect.invalid = true;
                console.log(key, 'invalid scriptEffect', this.action.scriptParameter.invalid, this.action.scriptEffect.invalid);
              }

              if (!this.validateSelection(selected.value)) {
                actionInvalid = true;
                this.action.scriptValue.invalid = true;
                console.log(key, 'invalid scriptValue', this.action.scriptEffect.invalid, this.action.scriptValue.invalid);
              }
            } else if (key === 'conditionParameter') {
              if (!this.validateSelection(selected.doif)) {
                actionInvalid = true;
                this.action.conditionDoIf.invalid = true;
                console.log(key, 'invalid conditionDoIf', selected);
              }

              if (!this.validateSelection(selected.value)) {
                actionInvalid = true;
                this.action.conditionValue.invalid = true;
                console.log(key, 'invalid conditionValue', selected);
              }
            } else if (!this.validateSelection(selected.value)) {
              actionInvalid = true;
              this.action[key].invalid = true;
              console.log(key, 'invalid', selected);
            }
          }
        }
      }
    }

    if (actionInvalid) { return; }

    this.actionNew = {
      status: this.action.status.selected.list[0].value[0] ? 'active' : 'disabled',
      name: this.action.name.selected.list[0].value,
      clientId: this.action.client.selected.values + '',
      gatewayId: this.action.gateway.selected.values + '',
      script: {
        deviceType: this.action.scriptType.selected.values + '',
        deviceId: this.action.scriptDevice.selected.values + '',
        parameter: [],
      },
      condition: this.action.conditionType.selected.values.length ? {
        deviceType: this.action.conditionType.selected.values + '',
        deviceId: this.action.conditionDevice.selected.values + '',
        parameter: [],
      } : {},
      schedule: {
        timeStart: this.datePipe.transform(new Date(this.action.scheduleStart.selected.value), 'yyyy-MM-ddTHH:mm:ssZZZZZ'),
        timeEnd: this.datePipe.transform(new Date(this.action.scheduleEnd.selected.value), 'yyyy-MM-ddTHH:mm:ssZZZZZ'),
        repeat: {
          key: this.action.scheduleRepeat.selected.list[0].key,
          value: +this.action.scheduleRepeat.selected.list[0].value,
        }
      }
    };

    for (const parameter of this.action.scriptParameter.selected.list) {
      const cloneParameter = {...parameter};
      if (cloneParameter.effect + '' === ACTION_SCRIPT_EFFECT.formula) {
        cloneParameter.value = JSON.parse(`[${cloneParameter.value}]`);
      } else {
        if (cloneParameter.input === INPUT_MODE.text) {
          cloneParameter.value = [JSON.parse(cloneParameter.value)];
        }
        cloneParameter.value = cloneParameter.value[0];
        if (cloneParameter.type === 'number') {
          cloneParameter.value = +cloneParameter.value;
        }
      }
      this.actionNew.script.parameter.push({
        field: cloneParameter.field,
        effect: cloneParameter.effect + '',
        value: cloneParameter.value,
      });
    }

    for (const parameter of this.action.conditionParameter.selected.list) {
      const cloneParameter = {...parameter};
      if (cloneParameter.input === INPUT_MODE.text) {
        cloneParameter.value = [JSON.parse(cloneParameter.value)];
      }
      cloneParameter.value = cloneParameter.value[0];
      if (cloneParameter.type === 'number') {
        cloneParameter.value = +cloneParameter.value;
      }
      this.actionNew.condition.parameter.push({
        field: cloneParameter.field,
        doif: cloneParameter.doif + '',
        value: cloneParameter.value,
      });
    }

    console.log('actionNew', this.actionNew);

    if (!this.actionOld) {
      const url = API.rootAPI + API.actions;
      const rs = await this.apiService.apiPostAsync(url, this.actionNew);
      console.log('create Action', rs);
    } else {

    }
  }

  generateForm(): void {
    if (this.actionOld) {
      console.log('generateForm action', this.actionOld);
      this.actionNew = {...this.action};
    } else {
      this.actionNew.name = '';
      this.actionNew.clientId = '';
      this.actionNew.gatewayId = '';
      this.actionNew.script = {
        value: '',
        formula: '',
        deviceType: '',
        deviceId: '',
      };
      this.actionNew.condition = {
        doIf: '',
        value: '',
        deviceId: '',
        deviceType: '',
      };
      this.actionNew.schedule = {
        timeStart: '',
        timeEnd: '',
        repeat: '',
      };
    }
  }
}
