import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

import { DevicesService } from '../../system/body/device-layout/devices/devices.service';

@Component({
  selector: 'app-change-state-popup',
  templateUrl: './change-state-popup.component.html',
  styleUrls: ['./change-state-popup.component.scss'],
})
export class ChangeStatePopupComponent implements OnInit {

  @Input() changeState: any;
  @Output() popup = new EventEmitter();

  keepDefault = false;
  expired = Math.floor(new Date().getTime() / 1000);


  constructor(
    private datePipe: DatePipe,
    private devicesService: DevicesService,
  ) { }

  ngOnInit(): void {
  }

  closePopup(): void {
    this.popup.emit();
  }

  onSubmit(): void {
    const now = Math.floor(new Date().getTime() / 1000);
    const time = Math.floor(new Date(this.expired).getTime() / 1000) - now;
    if (time < 60) {
      console.log('Error Change State: timeExpired have to later than now');
      return;
    }

    // let timeExpired = this.datePipe.transform(new Date(this.expired), 'yyyy-MM-ddTHH:mm:ssZZZZZ');
    const timeExpired = new Date(this.expired).getTime();

    const data = {
      devices: this.changeState.devices,
      deviceType: this.changeState.type,
      state: this.changeState.state,
      keepDefault: this.keepDefault,
      timeChange: now,
      timeExpired,
    };

    this.devicesService.changeState(data);
    this.closePopup();
  }
}
