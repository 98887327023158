<div class="bma_header">
    <h3 class="bma_hdr_title">{{language.common.deviceLayout}}</h3>
    <div class="bma_hdr_breadcrums">
        <a class="bma_hdr_brc_tab" (click)="goBack()">{{language.common.devices}}</a>
        <div class="bma_hdr_brc_item"> > </div>
        <div class="bma_hdr_brc_item">{{device?.name||''}}</div>
    </div>
</div>
<div class="bma_content">
    <div class="row dd_header">
        <div class="col-lg-3">
            <div class="dd_hdr_image" [ngClass]="{'dd_bg_online': device?.isOnline, 'dd_bg_offline': !device?.isOnline}">
            </div>
        </div>
        <div class="col-lg-9">
            <div class="dd_hdr_title">
                <div class="dd_hdr_status"
                    [ngClass]="{'dd_bg_online': device?.isOnline, 'dd_bg_offline': !device?.isOnline}">
                    {{device?.isOnline?language.itemInfo.statusOnline:language.itemInfo.statusOffline}}
                </div>
                <div class="dd_hdr_name">
                    {{device?.name}}
                </div>
            </div>
            <div class="row dd_hdr_description">
                <div class="col-lg-6">
                    <div class="dd_hdr_common">
                        <div class="dd_cmn_name">{{language.common.projects}}</div>
                        <div class="dd_cmn_value">N/A</div>
                    </div>
                    <div class="dd_hdr_common">
                        <div class="dd_cmn_name">{{language.common.areas}}</div>
                        <div class="dd_cmn_value">N/A</div>
                    </div>
                    <div class="dd_hdr_common">
                        <div class="dd_cmn_name">{{language.itemInfo.stateUpdateDuration}}</div>
                        <div class="dd_cmn_value">{{device?.stateUpdateDuration}}</div>
                    </div>
                    <div class="dd_hdr_common">
                        <div class="dd_cmn_name">{{language.itemInfo.lastUpdate}}</div>
                        <div class="dd_cmn_value">{{device?.amqpModifiedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="dd_hdr_common">
                        <div class="dd_cmn_name">{{language.itemInfo.macAddress}}</div>
                        <div class="dd_cmn_value">{{device?.macAddress}}</div>
                    </div>
                    <div class="dd_hdr_common">
                        <div class="dd_cmn_name">{{language.deviceDetail.deviceId}}</div>
                        <div class="dd_cmn_value">{{device?.deviceId}}</div>
                    </div>
                    <div class="dd_hdr_common">
                        <div class="dd_cmn_name">{{language.itemInfo.type}}</div>
                        <div class="dd_cmn_value">{{device?.type}}</div>
                    </div>
                    <div class="dd_hdr_common">
                        <div class="dd_cmn_name">{{language.itemInfo.version}}</div>
                        <div class="dd_cmn_value">{{device?.version}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="dd_body">
                <div class="dd_bd_title">
                    <div class="dd_db_caption">{{language.itemInfo.values}}</div>
                </div>
                <div class="dd_content" *ngFor="let data of device?.data; let dataIndex = index">
                    <div class="dd_bd_specific" [ngClass]="{'dd_bd_specific_even': dataIndex%2}">
                        <div class="dd_spcf_name">{{data.name}}</div>
                        <div class="dd_spcf_value">{{data.value}}</div>
                    </div>
                </div>
            </div>
            <div class="dd_body">
                <div class="dd_bd_title">
                    <div class="dd_db_caption">{{language.itemInfo.setting}}</div>
                </div>
                <div class="dd_content" *ngFor="let setting of device?.setting; let settingIndex = index">
                    <div class="dd_bd_specific" [ngClass]="{'dd_bd_specific_even': settingIndex%2}">
                        <div class="dd_spcf_name">{{setting.name}}</div>
                        <div class="dd_spcf_value">{{setting.value}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="dd_body">
                <div class="dd_bd_title">
                    <div class="dd_db_caption">{{language.itemInfo.chart}}</div>
                </div>
                <div class="chart-wrapper"> 
                    <canvas baseChart style="padding: 10px; font-size: 15px;"
                        [datasets]="chartRealtime.dataSets" 
                        [labels]="chartRealtime.labels" 
                        [options]="chartRealtime.options"
                        [colors]="chartRealtime.colors" 
                        [legend]="chartRealtime.legend" 
                        [chartType]="chartRealtime.type" 
                        [plugins]="chartRealtime.plugins">
                    </canvas>
                </div>
            </div>
            <div class="dd_body">
                <div class="dd_bd_title">
                    <div class="dd_db_caption">{{language.itemInfo.activities}}</div>
                </div>
            </div>
        </div>
    </div>
</div>