const LANGUAGES_EN = {
  common: {
    dashboard: 'Dashboard',
    automation: 'Automation',
    deviceLayout: 'Device layout',
    userManagement: 'User management',
    clients: 'Clients',
    client: 'Client',
    projects: 'Projects',
    project: 'Project',
    areas: 'Areas',
    area: 'Area',
    gateways: 'Gateways',
    gateway: 'Gateway',
    devices: 'Devices',
    device: 'Device',
  },
  auth: {
    login: 'Login',
    userName: 'Email',
    password: 'Password',
    forgetPassword: 'Forgot password',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    language: 'Language',
  },
  itemInfo: {
    macAddress: 'Mac address',
    name: 'Name',
    enter: 'Enter',
    enterName: 'Enter Name',
    id: 'Id',
    type: 'Type',
    status: 'Status',
    statusOnline: 'Online',
    statusOffline: 'Offline',
    filters: 'Filters',
    select: 'Select',
    stateUpdateDuration: 'State update duration',
    setting: 'Settings',
    values: 'Values',
    value: 'Value',
    parameter: 'Parameter',
    chart: 'Chart',
    activities: 'Activities',
    lastUpdate: 'Last update',
    version: 'Version',
    error: 'Error',
  },
  automation: {
    actions: 'Actions',
    action: 'Action',
    script: 'Script',
    effect: 'Effect',
    changeTo: 'Change to',
    adjust: 'Adjust',
    formula: 'Formula',
    condition: 'Condition',
    doIf: 'Do if',
    gt: 'Value >',
    lt: 'Value <',
    eq: 'Value =',
    diff: 'Value <>',
    schedule: 'schedule',
    startTime: 'Start time',
    endTime: 'End time',
    repeat: 'Repeat',
  },
  gateways: {

  },
  gatewayDetail: {
    macAddress: 'Mac address',
    gatewayName: 'Gateway Name',
    enterName: 'Enter Gateway\'s Name',
    gatewayId: 'Gateway id',
  },
  deviceLayout: {

  },
  deviceDetail: {
    macAddress: 'Mac address',
    deviceName: 'Device name',
    enterName: 'Enter Device\'s Name',
    deviceId: 'Device id',
    deviceType: 'Device type',
  },
  deviceType: {
    temperature: 'Temperature',
    tempHumi: 'Temperature & Humidity',
    repeater: 'Repeater',
    incubator: 'Incubator',
    relay: 'Relay',
    fan: 'Fan',
  },
  deviceValues: {
    temperature: 'Temperature',
    temperaturePower: 'Temperature power',
    humidity: 'Humidity',
    humidityPower: 'Humidity power',
    swingTimer: 'Swing timer',
    swingTime: 'Swing time',
    dateBegin: 'Date begin',
    daysGone: 'Days gone',
    state: 'State',
    power: 'Power',
    speed: 'Speed',
    swing: 'Swing',
    misting: 'Misting',
    other: 'Other',
    threshold: {
      max: 'max',
      min: 'min',
    },
  },
  unit: {
    sec: 's',
    second: 'Sec',
    minute: 'Min',
    hour: 'Hour',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    lux: 'Lux',
    celsius: '°C',
    percent: '%',
  }
};

const LANGUAGES_VI = {
  common: {
    dashboard: 'Tổng quan',
    automation: 'Tự động',
    deviceLayout: 'Danh mục thiết bị',
    userManagement: 'Quản lý người dùng',
    clients: 'Khách hàng',
    client: 'Khách hàng',
    projects: 'Dự án',
    project: 'Dự án',
    areas: 'Khu vực',
    area: 'Khu vực',
    gateways: 'Gateways',
    gateway: 'Gateway',
    devices: 'Thiết bị',
    device: 'Thiết bị',
  },
  auth: {
    login: 'Đăng nhập hệ thống',
    userName: 'Email',
    password: 'Mật khẩu',
    forgetPassword: 'Quên mật khẩu',
    signIn: 'Đăng nhập',
    signOut: 'Đăng xuất',
    language: 'Ngôn ngữ',
  },
  itemInfo: {
    macAddress: 'Địa chỉ Mac',
    name: 'Tên',
    enter: 'Nhập',
    enterName: 'Nhập Tên',
    id: 'Mã định danh',
    type: 'Loại',
    status: 'Trạng thái',
    statusOnline: 'T tuyến',
    statusOffline: 'N tuyến',
    filters: 'Bộ lọc',
    select: 'Chọn',
    stateUpdateDuration: 'Thời gian cập nhật',
    setting: 'Cài đặt',
    values: 'Giá trị',
    value: 'Giá trị',
    parameter: 'Thông số',
    chart: 'Biểu đồ',
    activities: 'Hoạt động',
    lastUpdate: 'Cập nhật',
    version: 'Phiên bản',
    error: 'Lỗi',
  },
  automation: {
    actions: 'Hành động',
    action: 'Hành động',
    script: 'Kịch bản',
    effect: 'Hiệu ứng',
    changeTo: 'Thay đổi',
    adjust: 'Điều chỉnh',
    formula: 'Công thức',
    condition: 'Điều kiện',
    doIf: 'Thực hiện nếu',
    gt: 'Giá trị >',
    lt: 'Giá trị <',
    eq: 'Giá trị =',
    diff: 'Giá trị <>',
    schedule: 'Lịch trình',
    startTime: 'Bắt đầu',
    endTime: 'Kết thúc',
    repeat: 'Lặp lại',
  },
  gateways: {

  },
  gatewayDetail: {
    macAddress: 'Địa chỉ Mac',
    gatewayName: 'Tên gateway',
    enterName: 'Nhập Tên Gateway',
    gatewayId: 'Id gateway',
  },
  deviceLayout: {

  },
  deviceDetail: {
    macAddress: 'Địa chỉ Mac',
    deviceName: 'Tên thiết bị',
    enterName: 'Nhập Tên Thiết Bị',
    deviceId: 'Id thiết bị',
    deviceType: 'Loại thiết bị',
  },
  deviceType: {
    temperature: 'Nhiệt độ',
    tempHumi: 'Nhiệt độ & Độ ẩm',
    repeater: 'Khuếch đại',
    incubator: 'Ấp trứng',
    relay: 'Rờ Le',
    fan: 'Quạt',
  },
  deviceValues: {
    temperature: 'Nhiệt độ',
    temperaturePower: 'Công suất nhiệt độ',
    humidity: 'Độ ẩm',
    humidityPower: 'Công suất độ ẩm',
    swingTimer: 'Thời gian đảo',
    swingTime: 'Thời gian đảo',
    dateBegin: 'Ngày ấp',
    daysGone: 'Số ngày',
    state: 'Trạng thái',
    power: 'Công suất',
    speed: 'Tốc độ',
    swing: 'Đảo',
    misting: 'Sương',
    other: 'Khác',
    threshold: {
      max: 'tối đa',
      min: 'tối thiểu',
    },
  },
  unit: {
    sec: 's',
    second: 'Giây',
    minute: 'Phút',
    hour: 'Giờ',
    day: 'Ngày',
    week: 'Tuần',
    month: 'Tháng',
    year: 'Năm',
    lux: 'Lux',
    celsius: '°C',
    percent: '%',
  }
};

export const LANGUAGES = {
  LANG: LANGUAGES_EN,
  EN: LANGUAGES_EN,
  VI: LANGUAGES_VI,
};
