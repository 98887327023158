import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { SystemService } from "../../../system.service";

import { API } from '../../../../_services/api'; 
import { AUTH } from '../../../../_services/local.storage';
import { DeviceInfo } from '../device-layout.model';

@Injectable({
    providedIn: 'root'
})
export class DeviceDetailService {

    device: DeviceInfo | undefined;

    httpOptions = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'Authorization': AUTH.token,
            }
        ),

        params: new HttpParams(),
    };

    constructor(
        private http: HttpClient,
        private systemService: SystemService,
    ) { }

    getDevice(deviceId: string) {
        let httpOptions = this.httpOptions;
        this.http.get(API.rootAPI + API.devices + `/${deviceId}`, httpOptions).subscribe(
            (response: any) => {
                this.systemService.setEventStreamDevice(response.data);
            },
            (error) => {
                console.log(error);
                this.systemService.apiCheckError(error);
            })
    }

    changeSettings(deviceId: string, body: any) {
        let httpOptions = this.httpOptions;
        this.http.patch(API.rootAPI + API.devices + `/${deviceId}/` + API.deviceSettings, body, httpOptions).subscribe(
            (response: any) => {
                console.log("changeSettings " + JSON.stringify(response.data));
            },
            (error) => {
                console.log(error);
                this.systemService.apiCheckError(error);
            })
    }
}
