import { AuthModule } from '../auth.module';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { LANGUAGES } from '../../_services/languages';
import { AUTH, AuthService } from './login.import';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
  @Input() language: any = LANGUAGES.VI;
  auth = AUTH;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    console.log(this.auth);
  }

  ngOnInit(): void {

  }

  onSubmit(): void {

  }

  onSignin(): void {
    let login = true;
    AUTH.alerts.length = 0;
    console.log('Sign In');
    if (!AUTH.username) {
      AUTH.alerts.push('Email should not be blank.');
      login = false;
    }
    if (!AUTH.password) {
      AUTH.alerts.push('Password should not be blank.');
      login = false;
    }
    if (login) {
      this.authService.doSignIn();
    }
  }
}
