<div class="ss_popup">
    <div class="ss_popup_backgound"></div>
    <div class="ss_popup_content">
        <div class="ss_pu_header">
            <div class="ss_pu_hdr_title">Advance Settings</div>
            <i class="bi bi-x ss_pu_hdr_close" (click)="closePopup()"></i>
        </div>
        <div class="ss_pu_body">
            <form class="ss_pu_form" [formGroup]="form">
                <div *ngFor="let settings of advanceSettings let settingsIndex=index">
                    <div class="as_bd_title">{{settings.title}}</div>
                    <div class="as_bd_content" [ngClass]="{'as_inactive': !setting.isSelected}" 
                         *ngFor="let setting of settings.content let settingIndex = index">
                        <label class="ss_checkbox">
                            <input type="checkbox" (change)="onSelected(settingsIndex,settingIndex)">
                            <span class="ss_checkmark"></span>
                        </label>
                        <div>{{setting.name}}</div>
                        <input formControlName="{{setting.key}}" type="text" [ngClass]="{'as_disabled': !setting.isSelected}">
                    </div>
                </div>
            </form>
            <div class="ss_pu_submit">
                <button class="ss_pu_btn_cancel" (click)="closePopup()">Cancel</button>
                <button class="ss_pu_btn_submit" (click)="onSubmit()">Submit</button>
            </div>
        </div>
    </div>
</div>