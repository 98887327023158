<div class="cpn_slider">
  <div class="cpn_sld_col">
    <input type="range" min="{{slider.min}}" max="{{slider.max}}" value="{{slider.value[0]}}" class="cpn_sld_bar"
      [(ngModel)]="slider.value[0]">
    <div class="cpn_sld_legends">
      <div class="cpn_sld_legend_left">{{slider.min + slider.unit}}</div>
      <div class="cpn_sld_legend_center">{{slider.center + slider.unit}}</div>
      <div class="cpn_sld_legend_right">{{slider.max + slider.unit}}</div>
    </div>
  </div>
  <div class="cpn_sld_value">{{slider.value[0]}}</div>
</div>