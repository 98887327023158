import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DevicesService } from './devices.service';
import { ClientsService } from '../../clients/clients.service';
import { GatewaysService } from '../../gateway-layout/gateways/gateways.service';

import { AUTH } from '../../../../_services/local.storage';
import { LANGUAGES } from '../../../../_services/languages';
import { DEVICE_TYPE } from '../../../../_services/constant';
import { DEVICE_LAYOUT, DeviceInfo } from '../device-layout.model';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  auth = AUTH;
  language: any = LANGUAGES.LANG;
  numberDevices = 0;
  devices: any[] = [];

  popup = {
    name: '',
    deviceDetail: 'deviceDetail',
    commonSettings: 'commonSettings',
    advanceSettings: 'advanceSettings',
    changeState: {
      name: 'changeState',
      active: false,
      type: '',
      devices: [] as any,
      state: false,
    }
  }

  selected = {
    number: 0,
    index: 0,
  }

  recordStart = 0;
  recordEnd = 0;
  pageIndex = 0;
  pageIndexs = [0, 1, 2];
  pageSizes = [5, 10, 20, 30, 50, 100];
  pageSize = 10;
  isPageSizeUp = false;

  filterWhere: any = {};
  filter: any = {
    main: {
      isDrop: false,
      isChange: false,
    },
    name: {
      title: 'name',
      selectedValue: ''
    },
    clients: {
      title: this.language.common.client,
      list: [],
      selectedValue: [],
      isChange: false,
    },
    projects: {
      title: this.language.common.project,
      list: [],
      selectedValue: [],
    },
    areas: {
      title: this.language.common.area,
      list: [],
      selectedValue: [],
    },
    deviceType: {
      title: this.language.deviceDetail.deviceType,
      list: [
        {
          name: this.language.deviceType.incubator,
          value: DEVICE_TYPE.incubator,
          isSelected: false,
        },
        {
          name: this.language.deviceType.relay,
          value: DEVICE_TYPE.relay,
          isSelected: false,
        },
        {
          name: this.language.deviceType.fan,
          value: DEVICE_TYPE.fan,
          isSelected: false,
        },
      ],
      selectedValue: <any>[],
    },
    status: {
      title: this.language.itemInfo.status,
      list: [
        {
          name: this.language.itemInfo.statusOnline,
          value: true,
          isSelected: false
        },
        {
          name: this.language.itemInfo.statusOffline,
          value: false,
          isSelected: false
        }
      ],
      selectedValue: <any>[],
    },
    gateways: <any> {
      title: 'gateway',
      list: <any>[],
      selectedValue: <any>[],
    },
  }

  isSelectedAlls = {
    devices: false,
    clients: false,
    projects: false,
    areas: false,
  }

  deviceType = DEVICE_TYPE; 
  dataResponse: any = {};
  constructor(
    private router: Router,
    private devicesService: DevicesService,
    private clientsService: ClientsService,
    private gatewaysService: GatewaysService,
  ) { 
  }

  async ngOnInit() {
    this.language = LANGUAGES.LANG;
    this.devicesService.language = LANGUAGES.LANG;
    this.devicesService.getEventStreamDevice();
    await this.getFilters();
    await this.getDevices();
  }

  async getFilters() {
    let filter: any = localStorage.getItem('devicesFilter');
    filter = filter ? JSON.parse(filter) : {};

    await this.getFilterName(filter.name || '');
    await this.getFilterClients(filter.clients || []);
    await this.getFilterProjects([]);
    await this.getFilterStatus(filter.status || []);
    await this.getFilterGateways(filter.gateways || []);
  }
  
  async getFilterName(oldFilter: string) {
    if (oldFilter) {
      this.filter.name.selectedValue = oldFilter;
      this.filterWhere.name = { "like":`%${this.filter.name.selectedValue}%`, "options":"i"};
    } else {
      delete this.filterWhere.name;
    }
  }

  async getFilterClients(oldFilter: any) {
    this.filter.clients.list = JSON.parse(await this.clientsService.getClients('{}')).data;
    this.filter.clients.selectedName = [];
    this.filter.clients.selectedValue = [];
    for (let i = 0; i < this.filter.clients.list.length; i++) {
      this.filter.clients.list[i].value = this.filter.clients.list[i].id;
      if (oldFilter.includes(this.filter.clients.list[i].value)) {
        this.filter.clients.list[i].isSelected = true;
        this.filter.clients.selectedName.push(this.filter.clients.list[i].name);
        this.filter.clients.selectedValue.push(this.filter.clients.list[i].value);
      }
    }

    if (this.filter.clients.selectedValue.length == 0) {
      delete this.filterWhere.clientId;
    } else {
      this.filterWhere.clientId = { "inq": this.filter.clients.selectedValue};
    }
  }

  async getFilterProjects(filterProjects: any[]) {
    this.filter.projects.list = [];
  }

  async getFilterStatus(oldFilter: any) {
    this.filter.status.selectedName = [];
    this.filter.status.selectedValue = [];
    for (let i = 0; i < this.filter.status.list.length; i++) {
      if (oldFilter.includes(this.filter.status.list[i].value)) {
        this.filter.status.list[i].isSelected = true;
        this.filter.status.selectedName.push(this.filter.status.list[i].name);
        this.filter.status.selectedValue.push(this.filter.status.list[i].value);
      }
    }

    if (this.filter.status.selectedValue.length != 1) {
      delete this.filterWhere.isOnline;
    } else {
        this.filterWhere.isOnline = this.filter.status.selectedValue[0];
    }
  }

  async getFilterGateways(oldFilter: any) {
    let filter = `{"where": {"clientId": { "inq": ${JSON.stringify(this.filter.clients.selectedValue)}}},"override":true}`;
    this.filter.gateways.list = JSON.parse(await this.gatewaysService.getGateways(filter)).data;
    this.filter.gateways.selectedName = [];
    this.filter.gateways.selectedValue = [];
    for (let i = 0; i < this.filter.gateways.list.length; i++) {
      this.filter.gateways.list[i].index = i;
      this.filter.gateways.list[i].value = this.filter.gateways.list[i].gatewayId;
      if (oldFilter.includes(this.filter.gateways.list[i].value)) {
        this.filter.gateways.list[i].isSelected = true;
        this.filter.gateways.selectedName.push(this.filter.gateways.list[i].name);
        this.filter.gateways.selectedValue.push(this.filter.gateways.list[i].value);
      }
    }

    if (this.filter.gateways.selectedValue.length == 0) {
      delete this.filterWhere.gatewayId;
    } else {
      this.filterWhere.gatewayId = { "inq": this.filter.gateways.selectedValue};
    }
  }

  submitFilterDevice(checkFilterChange: boolean) {

    if (checkFilterChange) this.checkFilterChange();

    let oldFilter = {
      name: this.filter.name.selectedValue || '',
      clients: this.filter.clients.selectedValue || [],
      projects: this.filter.projects.selectedValue || [],
      areas: this.filter.areas.selectedValue || [],
      deviceType: this.filter.deviceType.selectedValue || [],
      status: this.filter.status.selectedValue || [],
      gateways: this.filter.gateways.selectedValue || [],
    }

    localStorage.setItem('devicesFilter', JSON.stringify(oldFilter));
    this.getDevices();
    this.filter.main.isDrop = false;
  }

  async checkFilterChange() {
    for (let key in this.filter) {
      if (this.filter[key].isDrop && this.filter[key].isChange) {
        this.filter[key].isChange = false;
        this.changeFilterWhere(this.filter[key].title);
      }
    }
  }

  async changeFilterWhere(name: string) {
    switch (name) {
      case this.filter.name.title:
        if (!this.filter.name.selectedValue) {
          delete this.filterWhere.name;
        } else {
          this.filterWhere.name = { "like":`%${this.filter.name.selectedValue}%`, "options":"i"};
        }
        break;
      case this.filter.clients.title:
        if (this.filter.clients.selectedValue.length == 0) {
          delete this.filterWhere.clientId;
          delete this.filterWhere.projectId;
          delete this.filterWhere.areaId;
          delete this.filterWhere.gatewayId;

          this.filterRefresh(this.filter.projects);
          this.filterRefresh(this.filter.areas);
          this.filterRefresh(this.filter.gateways);
        } else {
          this.filterWhere.clientId = { "inq": this.filter.clients.selectedValue};
          await this.getFilterGateways([]);
        }
        this.filter.clients.isChange = false;
        break;
      case this.filter.deviceType.title:
        if (this.filter.deviceType.selectedValue.length == 0) {
          delete this.filterWhere.deviceType;
        } else {
          this.filterWhere.deviceType = { "inq": this.filter.deviceType.selectedValue};
        }
        break;
      case this.filter.status.title:
        if (this.filter.status.selectedValue.length != 1) {
          delete this.filterWhere.isOnline;
        } else {
            this.filterWhere.isOnline = this.filter.status.selectedValue[0];
        }
        break;
      case this.filter.gateways.title:
        console.log("changeFilterWhere gateways", this.filter.gateways.selectedValue);
        if (this.filter.gateways.selectedValue.length == 0) {
          delete this.filterWhere.gatewayId;
        } else {
          this.filterWhere.gatewayId = { "inq": this.filter.gateways.selectedValue};
        }
        break;
    }

    this.filter.main.isChange = true;
  }

  filterRefresh(filter: any) {
    filter.selectedName = [];
    filter.selectedValue = [];
    filter.list = [];
  }

  filterDrop() {
    this.filter.main.isDrop = !this.filter.main.isDrop;
    this.checkFilterChange();
    if (!this.filter.main.isDrop && this.filter.main.isChange) {
      this.submitFilterDevice(false);
    }
  }

  async getDevices() {
    let filter = `{"where":${JSON.stringify(this.filterWhere)},"limit":${this.pageSize},"skip":${this.pageSize*this.pageIndex},"override":true}`;
    let where = {...this.filterWhere, override: true };
    this.devicesService.countDevices(JSON.stringify(where));
    this.devicesService.updateDevices(JSON.parse(await this.devicesService.getDevices(filter)).data);

    this.devices = this.devicesService.devices;
    this.numberDevices = this.devicesService.numberDevices;
    if (this.devicesService.devices.length > 0) {
      this.recordStart = this.pageSize*this.pageIndexs[this.pageIndex] + 1;
      this.recordEnd = this.recordStart + this.devicesService.devices.length - 1;
    } else {
      this.recordStart = 0;
      this.recordEnd = 0;
    }
  }

  // getDevicesList() {
  //   this.devices = this.devicesService.devices;
  //   // this.devices = this.deviceService.getDevicesList();
  //   return this.devices;
  // }

  // getNumberDevices() {
  //   return this.devicesService.getNumberDevices();
  // }

  showPopup(name: string) {
    this.popup.name = name;
    // document.body.style.overflow = 'hidden';
  }

  closePopup() {
    this.popup.name = '';
    document.body.style.overflow = 'auto';
  }

  selectDevice(deviceIndex: number, isSelected: boolean) {
    console.log(`deviceIndex ${deviceIndex} isSelected ${isSelected}`);

    if (isSelected) this.selected.number++;
    else this.selected.number--;

    this.popup.changeState.type = '';
    this.popup.changeState.devices = [];
    if (this.selected.number > 0){
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].isSelected) {
          if (this.selected.number == 1) this.selected.index = i;
          if (this.popup.changeState.type == '') {
            this.popup.changeState.type = this.devices[i].deviceType;
            if (this.devices[i].deviceType == DEVICE_TYPE.relay) {
              if (this.selected.number == 1) this.popup.changeState.state = this.devices[i].metadata.state;
              else this.popup.changeState.state = false;
              this.popup.changeState.active = true;
              this.popup.changeState.devices.push(this.devices[i].id);
            }
          } else if(this.devices[i].deviceType == this.popup.changeState.type){
            this.popup.changeState.devices.push(this.devices[i].id);
          } else {
            this.popup.changeState.active = false;
          }
        }
      }
    } else {
      this.popup.changeState.active = false;
    }
  }

  selectAllDevices() {
    this.devicesService.selectAllDevice(this.isSelectedAlls.devices);

    if (this.isSelectedAlls.devices) this.selected.number = this.pageSize;
    else this.selected.number = 0;
  }

  changePageNumber(pageIdx: number) {
    this.pageIndex = pageIdx;
    this.getDevices();
  }

  nextPage(numberPages: number) {
    let nubmerDevices = this.devicesService.numberDevices;
    if (nubmerDevices < (this.pageIndexs[0] + numberPages)*this.pageSize) return;
    if (this.pageIndexs[0] + numberPages < 0) return;
    if (numberPages == 0 ) {
      numberPages = Math.floor(nubmerDevices/this.pageSize);
      numberPages = Math.floor(numberPages/3)*3;

      this.pageIndexs[0] = numberPages;
      this.pageIndexs[1] = numberPages + 1;
      this.pageIndexs[2] = numberPages + 2;
    } else if (numberPages == -1) {
      this.pageIndexs = [0, 1, 2];
    } 
    else {
      this.pageIndexs[0] += numberPages;
      this.pageIndexs[1] += numberPages;
      this.pageIndexs[2] += numberPages;
    }

    this.getDevices();
  }

  changePageSize(pageIdx: number) {
    switch (pageIdx) {
      case 0:
        this.pageSize = 5;
        break;
      case 2:
        this.pageSize = 20;
        break;
      case 3:
        this.pageSize = 30;
        break;
      case 4:
        this.pageSize = 50;
        break;
      case 5:
        this.pageSize = 100;
        break;
      case 1:
      default:
        this.pageSize = 10;
        break;
    }

    this.pageIndex = 0;
    this.pageIndexs = [0, 1, 2];
    this.getDevices();
  }

  deviceDetail(index: number) {
    let device = this.devicesService.devices[index];
    console.log("deviceDetail " + JSON.stringify(device));
    this.router.navigate([`device-layout/device/${device.id}`]);
  }
}