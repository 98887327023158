import { Component, OnInit } from '@angular/core';

import { LANGUAGES } from '../../../_services/languages';

@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.scss']
})
export class AutomationComponent implements OnInit {

  language: any = LANGUAGES.LANG;
  numberActions = 0;
  tab = this.language.automation.actions;
  
  constructor() { }

  ngOnInit() {
    this.language = LANGUAGES.LANG;
    this.tab = this.language.automation.actions;
  }

  onChangeTab(tab: string) {
    this.tab = tab;
  }
}
