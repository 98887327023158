import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { ShareModule } from '../../../share.module';

import { 
  AutomationComponent,
  ActionsComponent,
  ActionPopupComponent,
} from './automation.import';

const routes: Routes = [
  {
    path: "",
    component: AutomationComponent,
    children: [
      {
        path: "",
        component: AutomationComponent,
      }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    ShareModule,
  ],
  declarations: [
    AutomationComponent,
    ActionsComponent,
    ActionPopupComponent,
  ]
})
export class AutomationModule { }
