import {StompConfig, StompHeaders} from '@stomp/ng2-stompjs';

export const rabbitmqConfig: StompConfig = {
  // Which server?
  url: 'wss://rabbitmq.freedomks.ml:12467/ws',
  // url: 'ws://freedomks.tk:12470/ws',

  // Headers
  // Typical keys: login, passcode, host
  headers: {
    login: 'web_airi',
    passcode: 'web_airi_pass',
    host: 'airi',
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeat_in: 0, // Typical value 0 - disabled
  heartbeat_out: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 5000 (5 seconds)
  reconnect_delay: 5000,

  // Will log diagnostics on console
  // debug: true
  debug: false,
};

export const rabbitmqHeaders: StompHeaders = {
  exclusive: 'true',
};
