import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../auth/auth.service';
import { SystemService } from '../system.service';

import { AUTH } from '../../_services/local.storage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  auth = AUTH;
  showUserInfo = false;
  isMenuFull = true;

  constructor(
    private authService: AuthService,
    private systemService: SystemService,
  ) { }

  ngOnInit(): void {
    const size = {
      target: window
    };
    this.onResize(size);
  }

  onResize(event: any): void {
    if (event.target.innerWidth < 1080) {
      if (!this.systemService.isMenuClose){
        this.systemService.isMenuFull = false;
        this.systemService.isMenuToggle = true;
      }
      this.systemService.closeMenu(true);
    } else {
      this.systemService.closeMenu(false);
      this.systemService.isMenuFull = this.isMenuFull;
      this.systemService.isMenuToggle = false;
    }
  }

  toggleMenu(): void {
    if (!this.systemService.isMenuClose) {
      this.isMenuFull = !this.systemService.isMenuFull;
    }
    this.systemService.toggleMenu();
  }

  getMenuClose(): boolean {
    return this.systemService.isMenuClose;
  }

  onLogout(): void {
    this.authService.doSignOut();
  }
}
