export const DEVICE_LAYOUT = {
    tab: {
        projects: 'Projects',
        areas: 'Areas',
        devices: 'Devices'
    },
}

export interface DeviceInfo {
    id: string,
    isOnline: boolean,
    deviceId: string,
    name: string,
    deviceType: string,
    metadata: any,
    subValue: string,
    areaName: string,
    lastUpdate: string,
    amqpModifiedAt: string,
    isSelected: boolean;
    shadow: any;
}