import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClickOutsideDirective } from './_directives/click-outside.directive';

import { MultiselectComponent } from './_components/multiselect/multiselect.component';
import { SelectionComponent } from './_components/selection/selection.component';
import { StateComponent } from './_components/state/state.component';
import { SliderComponent } from './_components/slider/slider.component';
import { TextComponent } from './_components/text/text.component';
import { CalendarComponent } from './_components/calendar/calendar.component';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    MultiselectComponent,
    SelectionComponent,
    StateComponent,
    SliderComponent,
    TextComponent,
    CalendarComponent,
   ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    ClickOutsideDirective,
    MultiselectComponent,
    SelectionComponent,
    StateComponent,
    SliderComponent,
    TextComponent,
    CalendarComponent,
  ]
})
export class ShareModule {}
