<div class="bma_tool_bar row">
  <div class="col-lg-6" style="display: flex; align-self: center;">
    <div class="bma_dropdown">
      <div class="bma_dropdown_menu" *ngIf="selected.number===1" (click)="showPopup(popup.deviceDetail)">
        <i class="bi bi-file-earmark-text" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">Detail</span>
      </div>
      <div class="bma_dropdown_menu" *ngIf="selected.number===1">
        <i class="bi bi-pencil-square" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">Edit</span>
      </div>
      <div class="bma_dropdown_menu" *ngIf="selected.number===1">
        <i class="bi bi-gear-wide-connected" style="vertical-align: middle;"></i>
        <div class="bma_dm_content">
          <div class="bma_dmc_title">SETTINGS</div>
          <div class="bma_dmc_select">
            <i class="bi bi-gear"></i>
            <span style="padding-left: 10px;" >Common Settings</span>
          </div>
          <div class="bma_dmc_select" (click)="showPopup(popup.advanceSettings)">
            <i class="bi bi-gear"></i>
            <span style="padding-left: 10px;">Advance Settings</span>
          </div>
        </div>
      </div>
      <div class="bma_dropdown_menu" *ngIf="popup.changeState.active" 
          (click)="showPopup(popup.changeState.name)">
        <i class="bi bi-power" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">On/Off</span>
      </div>
    </div>
  </div>
  <div class="bma_filter col-lg-6">
    <div class="bma_flt_header" (click)="filterDrop()">
      <i class="bi bi-funnel"></i>
      <span class="bma_flt_title">{{language.itemInfo.filters}}</span>
      <i class="bma_flt_icon" [ngClass]="{'bi bi-chevron-down': !filter.main.isDrop, 'bi bi-chevron-up':filter.main.isDrop}"></i>
    </div>
    <div class="bma_flt_body" *ngIf="filter.main.isDrop">
      <div class="bma_flt_content">
        <div style="padding-right: 10px;">
          <div  class="bma_flt_detail"> 
            <div>{{language.deviceDetail.deviceName}}:</div>
            <input class="bma_selected" type="text" [(ngModel)]="filter.name.selectedValue" (input)="filter.name.isChange=true; filter.name.isDrop=true; filter.main.isChange=true" placeholder="{{language.itemInfo.enterName}}">
          </div>
          <div *ngIf="!auth.clientId" class="bma_flt_detail">
            <div>{{language.common.clients}}:</div>
            <app-multiselect [filter]="filter.clients" (value)="changeFilterWhere(this.filter.clients.title)"></app-multiselect>
          </div>
          <div class="bma_flt_detail">
            <div>{{language.common.projects}}:</div>
            <div [ngClass]="{'bma_flt_disable': !auth.clientId && filter.clients.selectedValue.length===0}">
              <app-multiselect [filter]="filter.projects"></app-multiselect>
            </div>
          </div>
          <div class="bma_flt_detail">
            <div>{{language.common.areas}}:</div>
            <div [ngClass]="{'bma_flt_disable': filter.projects.selectedValue}">
              <app-multiselect [ngClass]="{'bma_flt_disable': filter.areas.list}" [filter]="filter.areas"></app-multiselect>
            </div>
          </div>
          <div class="bma_flt_detail">
            <div>{{language.deviceDetail.deviceType}}:</div>
            <app-multiselect [filter]="filter.deviceType" (value)="changeFilterWhere(this.filter.deviceType.title)"></app-multiselect>
          </div>
          <div class="bma_flt_detail">
            <div>{{language.itemInfo.status}}:</div>
            <app-multiselect [filter]="filter.status" (value)="changeFilterWhere(this.filter.status.title)"></app-multiselect>
          </div>
          <div class="bma_flt_detail">
            <div>Gateways:</div>
            <div [ngClass]="{'bma_flt_disable': !auth.clientId && (filter.clients.selectedValue.length===0 || filter.clients.isChange)}">
              <app-multiselect [filter]="filter.gateways" (value)="changeFilterWhere(this.filter.gateways.title)"></app-multiselect>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="bma_flt_clear">
          <i class="bi bi-backspace-fill"></i>
        </div>
        <div class="bma_flt_submit" (click)="submitFilterDevice(true)">
          <i class="bi bi-funnel-fill"></i>
          <span style="padding-left: 5px; font-size: 13px;">SEARCH</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="dl_tab_header row"> -->
  <!-- <div class="dl_tab_hdr_capption col-lg-6">
    Caption
  </div>
  <div class="dl_tab_hdr_tools col-lg-6">
    Tools
  </div> -->
<!-- </div> -->
<div class="dl_tab_panel row">
  <table class="bma_table">
    <thead class="bma_table_header">
      <tr style="text-transform: uppercase;">
        <th class="bma_table_cell">
          <div style="height: 18px;">
            <label class="ss_checkbox">
              <input type="checkbox" [(ngModel)]="isSelectedAlls.devices" (change)="selectAllDevices()">
              <span class="ss_checkmark"></span>
            </label>
          </div>
        </th>
        <th class="bma_table_cell">
          <span>##</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.itemInfo.status}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.common.device}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.itemInfo.type}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.itemInfo.value}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.common.area}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.itemInfo.lastUpdate}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr [ngClass]="{'bma_row_active': device.isSelected}" 
          *ngFor="let device of devices; let deviceIdx = index">
        <td class="bma_table_cell">
          <div style="height: 18px;">
            <label class="ss_checkbox">
              <input type="checkbox" [(ngModel)]="device.isSelected" (change)="selectDevice(deviceIdx, device.isSelected)">
              <span class="ss_checkmark"></span>
            </label>
          </div>
        </td>
        <td class="bma_table_cell">
          <span>{{pageSize*pageIndexs[pageIndex] + deviceIdx + 1}}</span>
        </td>
        <td class="bma_table_cell_W85" (click)="deviceDetail(deviceIdx)">
          <div [ngClass]="{'d_status_online': device.isOnline, 'd_status_offline': !device.isOnline}"> 
            <i class="bi bi-circle-fill"></i>
            <span style="padding-left: 5px;">{{ device.isOnline ? language.itemInfo.statusOnline: language.itemInfo.statusOffline}}</span>
          </div>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{device.name}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{device.type}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{device.value}}</span>
          <span class="bma_tooltip" *ngIf="device.valueTip">{{device.valueTip}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{device.areaName}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{device.amqpModifiedAt | date:'dd/MM/yyyy HH:mm:ss'}}</span>
        </td>
      </tr>
    </tbody>
    <thead class="bma_table_header">
      <tr>
        <th class="bma_table_cell">
            <span></span>
        </th>
        <th class="bma_table_cell">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
      </tr>
    </thead>
  </table>
  <div class="bpanel_paging">
    <div *ngIf="pageIndexs[1]*pageSize<numberDevices" class="bpanel_pager_nav">
      <div class="bpanel_pager_link" (click)="nextPage(-1)">
        <i class="bi bi-chevron-double-left"></i>
      </div>
      <div *ngIf="pageIndexs[1]*pageSize<numberDevices" class="bpanel_pager_link">
        <i class="bi bi-chevron-left" (click)="nextPage(-3)"></i>
      </div>
      <div *ngIf="pageIndexs[1]*pageSize<numberDevices" class="bpanel_pager_link bpanel_pager_number" 
        [ngClass]="{'bpanel_pager_number_select': pageIndex===0}" 
        (click)="changePageNumber(0)">{{pageIndexs[0] + 1}}
      </div>
      <div *ngIf="pageIndexs[1]*pageSize<numberDevices" class="bpanel_pager_link bpanel_pager_number" 
        [ngClass]="{'bpanel_pager_number_select': pageIndex===1}" 
        (click)="changePageNumber(1)">{{pageIndexs[1] + 1}}
      </div>
      <div *ngIf="pageIndexs[2]*pageSize<numberDevices" class="bpanel_pager_link bpanel_pager_number" 
      [ngClass]="{'bpanel_pager_number_select': pageIndex===2}" 
      (click)="changePageNumber(2)">{{pageIndexs[2] + 1}}
    </div>
    <div *ngIf="pageIndexs[1]*pageSize<numberDevices" class="bpanel_pager_link" (click)="nextPage(3)">
      <i class="bi bi-chevron-right"></i>
    </div>
    <div *ngIf="pageIndexs[1]*pageSize<numberDevices" class="bpanel_pager_link" (click)="nextPage(0)">
      <i class="bi bi-chevron-double-right"></i>
    </div>
    </div>
    <div class="bpanel_pager_info">
      <div class="bpanel_pi_size" clickOutside (clickOutside)="isPageSizeUp=false" (click)="isPageSizeUp ? isPageSizeUp=false : isPageSizeUp=true">
        <div class="bpanel_pi_selects" *ngIf="isPageSizeUp">
          <div *ngFor="let item of pageSizes; let i = index">
            <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === item}" (click)="changePageSize(i)">{{item}}</div>
          </div>
        </div>
        <span style="padding-right: 10px;">{{pageSize}}</span>
        <i class="bma_filter_icon bi bi-chevron-down"></i>
      </div>
      <div class="bpanel_pi_detail">Displaying {{recordStart}} - {{recordEnd}} Of {{numberDevices}} Records</div>
    </div>
  </div>
</div>
<app-device-detail-popup *ngIf="popup.name===popup.deviceDetail" (popup)="closePopup()"></app-device-detail-popup>
<app-advance-settings-popup *ngIf="popup.name===popup.advanceSettings" [device]="devices[selected.index]" (popup)="closePopup()"></app-advance-settings-popup>
<app-change-state-popup *ngIf="popup.name===popup.changeState.name" [changeState]="popup.changeState" (popup)="closePopup()"></app-change-state-popup>