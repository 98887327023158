<div class="ss_popup">
  <div class="ss_popup_backgound"></div>
  <div class="ss_popup_content">
    <div class="ss_pu_header">
      <div class="ss_pu_hdr_title">Action</div>
      <i class="bi bi-x ss_pu_hdr_close" (click)="closePopup()"></i>
    </div>
    <div class="ss_pu_body">
      <div class="ss_pu_form">
        <div class="act_row">
          <div class="act_col_left act_col">
            <div class="act_title" [ngClass]="{'ss_cmn_required': action.name.invalid}">
              {{action.name.selection.title}}</div>
            <div class="act_content">
              <app-text [title]="action.name.selection.title" [text]="action.name.selected.list[0]"></app-text>
            </div>
          </div>
        </div>
        <div class="act_row">
          <div class="act_col_left act_col" *ngIf="!auth.clientId">
            <div class="act_title" [ngClass]="{'ss_cmn_required': action.client.invalid}">
              {{action.client.selection.title}}</div>
            <div class="act_content">
              <app-selection [selection]="action.client.selection" [selected]="action.client.selected"
                (event)="changeSelection(action.client.selection.title, $event)">
              </app-selection>
            </div>
          </div>
          <div class="act_col_right act_col">
            <div class="act_title" [ngClass]="{'ss_cmn_required': !action.client.invalid && action.gateway.invalid}">
              {{action.gateway.selection.title}}
            </div>
            <div class="act_content" [ngClass]="{'ss_cmn_disable': !auth.clientId && action.client.invalid}">
              <app-selection [selection]="action.gateway.selection" [selected]="action.gateway.selected"
                (event)="changeSelection(action.gateway.selection.title, $event)">
              </app-selection>
            </div>
          </div>
        </div>
        <!-- Script -->
        <div class="act_row act_title">{{language.automation.script}}</div>
        <div class="act_row">
          <div class="act_col_left act_col">
            <div class="act_subtitle"
              [ngClass]="{'ss_cmn_required': !action.gateway.invalid && action.scriptType.invalid}">
              {{action.scriptType.selection.title}}
            </div>
            <div class="act_content" [ngClass]="{'ss_cmn_disable': action.gateway.invalid}">
              <app-selection [selection]="action.scriptType.selection" [selected]="action.scriptType.selected"
                (event)="changeSelection(action.scriptType.selection.title + 'script', $event)">
              </app-selection>
            </div>
          </div>
          <div class="act_col_right act_col">
            <div class="act_subtitle"
              [ngClass]="{'ss_cmn_required': !action.scriptType.invalid && action.scriptDevice.invalid}">
              {{action.scriptDevice.selection.title}}
            </div>
            <div class="act_content" [ngClass]="{'ss_cmn_disable': action.scriptType.invalid}">
              <app-selection [selection]="action.scriptDevice.selection" [selected]="action.scriptDevice.selected"
                (event)="action.scriptDevice.invalid = !validateSelection(action.scriptDevice.selected.values)">
              </app-selection>
            </div>
          </div>
        </div>
        <div class="act_row">
          <div class="act_row act_col_left">
            <!-- Script Parameter -->
            <div class="act_col">
              <div class="act_subtitle"
                [ngClass]="{'ss_cmn_required': !action.scriptType.invalid && action.scriptParameter.invalid}">
                {{action.scriptParameter.selection.title}}
              </div>
              <div class="act_content" *ngFor="let item of action.scriptParameter.selected.list">
                <app-selection [selection]="action.scriptParameter.selection"
                  [selected]="action.scriptParameter.selected" [share]="true" [name]="item.name" [unSelection]="true"
                  (event)="changeSelection(action.scriptParameter.selection.title + 'script', $event)">
                </app-selection>
              </div>
              <div class="act_content" [ngClass]="{'ss_cmn_disable': action.scriptType.invalid}"
                *ngIf="!action.scriptParameter.selection.disable">
                <app-selection [selection]="action.scriptParameter.selection"
                  [selected]="action.scriptParameter.selected" [share]="true" [name]="''"
                  (event)="changeSelection(action.scriptParameter.selection.title + 'script', $event)">
                </app-selection>
              </div>
            </div>
            <!-- Script Effect -->
            <div class="act_col">
              <div class="act_subtitle"
                [ngClass]="{'ss_cmn_required': !action.scriptParameter.invalid && action.scriptEffect.invalid}">
                {{action.scriptEffect.selection.title}}
              </div>
              <div class="act_content" *ngFor="let item of action.scriptParameter.selected.list">
                <app-selection [selection]="action.scriptEffect.selection"
                  (event)="item.effect = $event.values; item.value = null; changeSelection(action.scriptEffect.selection.title ,$event)">
                </app-selection>
              </div>
              <div class="act_content ss_cmn_disable" *ngIf="!action.scriptParameter.selection.disable">
                <app-selection [selection]="action.scriptEffect.selection"></app-selection>
              </div>
            </div>
          </div>
          <!-- Script Value -->
          <div class="act_col_right">
            <div class="act_col">
              <div class="act_subtitle" [ngClass]="{'ss_cmn_required': !action.scriptEffect.invalid && action.scriptValue.invalid}">
                {{action.scriptValue.selection.title}}
              </div>
              <div class="act_content" [ngClass]="{'ss_cmn_disable': !item.effect}"
                *ngFor="let item of action.scriptParameter.selected.list">
                <div *ngIf="item.input === inputMode.state && (item.effect + '') !== actionScriptEffect.formula">
                  <app-state [state]="item" [size]="13" (event)="item.value=$event"></app-state>
                </div>
                <div class="" *ngIf="item.input === inputMode.slider && (item.effect + '') !== actionScriptEffect.formula">
                  <app-slider [slider]="item" (event)="item.value=$event"></app-slider>
                </div>
                <div *ngIf="item.input === inputMode.text || (item.effect + '') === actionScriptEffect.formula">
                  <app-text [title]='action.scriptValue.selection.title' [text]="item" (event)="item.value=$event">
                  </app-text>
                </div>
              </div>
              <div class="act_content ss_cmn_disable" *ngIf="!action.scriptParameter.selection.disable">
                <app-selection [selection]="{title: action.scriptValue.selection.title}"></app-selection>
              </div>
            </div>
          </div>
        </div>
        <!-- Condition -->
        <div class="act_row act_title">{{language.automation.condition}}</div>
        <div class="act_row">
          <div class="act_col_left act_col">
            <div class="act_subtitle">{{action.conditionType.selection.title}}</div>
            <div [ngClass]="{'ss_cmn_disable': action.gateway.invalid}">
              <app-selection [selection]="action.conditionType.selection" [selected]="action.conditionType.selected"
                [unSelection]="true"
                (event)="changeSelection(this.action.conditionType.selection.title + 'condition', $event)">
              </app-selection>
            </div>
          </div>
          <div class="act_col_right act_col">
            <div class="act_subtitle"
              [ngClass]="{'ss_cmn_required': !action.conditionType.invalid && action.conditionDevice.invalid}">
              {{action.conditionDevice.selection.title}}
            </div>
            <div [ngClass]="{'ss_cmn_disable': action.conditionType.invalid}">
              <app-selection [selection]="action.conditionDevice.selection" [selected]="action.conditionDevice.selected"
                (event)="action.conditionDevice.invalid = !validateSelection(action.conditionDevice.selected.values)">
              </app-selection>
            </div>
          </div>
        </div>
        <div class="act_row">
          <div class="act_row act_col_left">
            <!-- Condition Parameter -->
            <div class="act_col">
              <div class="act_subtitle"
                [ngClass]="{'ss_cmn_required': !action.conditionType.invalid && action.conditionParameter.invalid}">
                {{action.conditionParameter.selection.title}}
              </div>
              <div class="act_content" *ngFor="let item of action.conditionParameter.selected.list">
                <app-selection [selection]="action.conditionParameter.selection"
                  [selected]="action.conditionParameter.selected" [share]="true" [name]="item.name" [unSelection]="true"
                  (event)="changeSelection(action.conditionParameter.selection.title + 'condition', $event)">
                </app-selection>
              </div>
              <div class="act_content" [ngClass]="{'ss_cmn_disable': action.conditionType.invalid}"
                *ngIf="!action.conditionParameter.selection.disable">
                <app-selection [selection]="action.conditionParameter.selection"
                  [selected]="action.conditionParameter.selected" [share]="true" [name]="''"
                  (event)="changeSelection(action.conditionParameter.selection.title + 'condition', $event)">
                </app-selection>
              </div>
            </div>
            <!-- Condition DoIf -->
            <div class="act_col">
              <div class="act_subtitle"
                [ngClass]="{'ss_cmn_required': !action.conditionParameter.invalid && action.conditionDoIf.invalid}">
                {{action.conditionDoIf.selection.title}}
              </div>
              <div class="act_content" *ngFor="let item of action.conditionParameter.selected.list">
                <app-selection [selection]="action.conditionDoIf.selection"
                  (event)="item.doif = $event.values; item.value = null; changeSelection(action.conditionDoIf.selection.title ,$event)">
                </app-selection>
              </div>
              <div class="act_content ss_cmn_disable" *ngIf="!action.conditionParameter.selection.disable">
                <app-selection [selection]="action.conditionDoIf.selection"></app-selection>
              </div>
            </div>
          </div>
          <!-- Condition Value -->
          <div class="act_col_right">
            <div class="act_col">
              <div class="act_subtitle" [ngClass]="{'ss_cmn_required': !action.conditionDoIf.invalid && action.conditionValue.invalid}">
                {{action.conditionValue.selection.title}}
              </div>
              <div class="act_content" [ngClass]="{'ss_cmn_disable': !item.doif}"
                *ngFor="let item of action.conditionParameter.selected.list">
                <div *ngIf="item.input === inputMode.state">
                  <app-state [state]="item" [size]="13" (event)="item.values=$event"></app-state>
                </div>
                <div class="" *ngIf="item.input === inputMode.slider">
                  <app-slider [slider]="item" (event)="item.values=$event"></app-slider>
                </div>
                <div *ngIf="item.input === inputMode.text">
                  <app-text [title]='action.scriptValue.selection.title' [text]="item" (event)="item.values=$event">
                  </app-text>
                </div>
              </div>
              <div class="act_content ss_cmn_disable" *ngIf="!action.conditionParameter.selection.disable">
                <app-selection [selection]="{title: action.conditionValue.selection.title}"></app-selection>
              </div>
            </div>
          </div>
        </div>
        <!-- Schedule -->
        <div class="act_row act_title">{{language.automation.schedule}}</div>
        <div class="act_row"> 
          <div class="act_col_left act_col">
            <div class="act_subtitle"
              [ngClass]="{'ss_cmn_required': action.scheduleStart.selected.value === '' || action.scheduleStart.invalid}">
              {{action.scheduleStart.selection.title}}
            </div>
            <app-calendar [calendar]="action.scheduleStart.selected"
              (event)="changeSelection(this.action.scheduleStart.selection.title, $event)"></app-calendar>
          </div>
          <div class="act_col_right act_col">
            <div class="act_subtitle"
              [ngClass]="{'ss_cmn_required': action.scheduleEnd.selected.value === '' || action.scheduleEnd.invalid}">
              {{action.scheduleEnd.selection.title}}
            </div>
            <app-calendar [calendar]="action.scheduleEnd.selected"
              (event)="changeSelection(this.action.scheduleEnd.selection.title, $event)">
            </app-calendar>
          </div>
        </div>
        <div class="act_row">
          <div class="act_col_left act_col">
            <div class="act_subtitle">{{action.scheduleRepeat.selection.title}}</div>
            <app-text [title]="action.scheduleRepeat.selection.title" 
              [text]="action.scheduleRepeat.selected.list[0]"
              [units]="action.scheduleRepeat"></app-text>
          </div>
          <!-- <div class="act_col_right act_col"></div> -->
        </div>
        <!-- Status -->
        <div class="act_row act_title">{{language.itemInfo.status}}</div>
        <div class="act_row">  
          <div class="act_col_left act_col">
            <app-state [state]="action.status.selected.list[0]" [showTitle]="false" [size]="19"></app-state>
          </div>
        </div>
      </div>
      <div class="ss_pu_submit">
        <button class="ss_pu_btn_cancel" (click)="closePopup()">Cancel</button>
        <button class="ss_pu_btn_submit" (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </div>
</div>