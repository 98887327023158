import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthModule } from './auth/auth.module';
import { SystemModule } from './system/system.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';

import { AuthRoutingModule, SystemRoutingModule} from './app.import';
import { ShareModule } from './share.module';

@NgModule({
  declarations: [
    AppComponent,
   ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    ChartsModule,
    AppRoutingModule,
    AuthRoutingModule,
    SystemRoutingModule,
    ShareModule,
    AuthModule,
    SystemModule,
    PageNotFoundModule,
  ],
  providers: [
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
