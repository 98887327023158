<div class="ss_popup">
    <div class="ss_popup_backgound"></div>
    <div class="ss_popup_content">
        <div class="ss_pu_header">
            <div class="ss_pu_hdr_title">Change State</div>
            <i class="bi bi-x ss_pu_hdr_close" (click)="closePopup()"></i>
        </div>
        <div class="ss_pu_body">
            <div class="cs_state">
                <span class="cs_title" [ngClass]="{'cs_inactive': changeState.state}">Off</span>
                <span class="cs_icon" (click)="changeState.state=!changeState.state">
                    <i class="bi bi-toggle-off cs_off" *ngIf="!changeState.state"></i>
                    <i class="bi bi-toggle-on cs_on" *ngIf="changeState.state"></i>
                </span>
                <span class="cs_title" [ngClass]="{'cs_inactive': !changeState.state}">On</span>
            </div>
            <div class="cs_options">
                <div class="cs_opts_title">Keep default:</div>
                <div style="height: 16px;">
                    <label class="ss_checkbox">
                        <input type="checkbox" (change)="keepDefault=!keepDefault">
                        <span class="ss_checkmark"></span>
                    </label>
                </div>
            </div>
            <div class="cs_options">
                <div class="cs_opts_title">Expired at:</div>
                <div class="cs_opts_content">
                    <!-- <i class="bi bi-calendar-check"></i> -->
                    <input type="datetime-local" [(ngModel)]="expired">
                </div>
            </div>
            <div class="ss_pu_submit">
                <button class="ss_pu_btn_cancel" (click)="closePopup()">Cancel</button>
                <button class="ss_pu_btn_submit" (click)="onSubmit()">Submit</button>
            </div>
        </div>
    </div>
</div>