<div class="bma_cnt_plt_tool_bar row">
  <div class="col-lg-6" style="display: flex; align-self: center;">
    <div class="bma_dropdown">
      <div class="bma_dropdown_menu" *ngIf="selected.number===1" (click)="showPopup(popup.deviceDetail)">
        <i class="bi bi-file-earmark-text" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">Detail</span>
      </div>
      <div class="bma_dropdown_menu" *ngIf="selected.number===1">
        <i class="bi bi-pencil-square" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">Edit</span>
      </div>
      <div class="bma_dropdown_menu" *ngIf="selected.number===1">
        <i class="bi bi-gear-wide-connected" style="vertical-align: middle;"></i>
        <div class="bma_dm_content">
          <div class="bma_dmc_title">SETTINGS</div>
          <div class="bma_dmc_select">
            <i class="bi bi-gear"></i>
            <span style="padding-left: 10px;" >Common Settings</span>
          </div>
          <div class="bma_dmc_select" (click)="showPopup(popup.advanceSettings)">
            <i class="bi bi-gear"></i>
            <span style="padding-left: 10px;">Advance Settings</span>
          </div>
        </div>
      </div>
      <!-- <div class="bma_dropdown_menu" *ngIf="popup.changeState.active" 
          (click)="showPopup(popup.changeState.name)">
        <i class="bi bi-power" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">On/Off</span>
      </div> -->
    </div>
  </div>
  <div class="bma_filter col-lg-6">
    <div class="bma_flt_header" (click)="filterDrop()">
      <i class="bi bi-funnel"></i>
      <span class="bma_flt_title">{{language.itemInfo.filters}}</span>
      <i class="bma_flt_icon" [ngClass]="{'bi bi-chevron-down': !filter.main.isDrop, 'bi bi-chevron-up':filter.main.isDrop}"></i>
    </div>
    <div class="bma_flt_body" *ngIf="filter.main.isDrop">
      <div class="bma_flt_content">
        <div style="padding-right: 10px;">
          <div  class="bma_flt_detail"> 
            <div>{{language.gatewayDetail.gatewayName}}:</div>
            <input class="bma_selected" type="text" [(ngModel)]="filter.name.selectedValue" (input)="filter.name.isChange=true; filter.name.isDrop=true; filter.main.isChange=true" placeholder="{{language.itemInfo.enterName}}">
          </div>
          <div *ngIf="!auth.clientId" class="bma_flt_detail">
            <div>{{language.common.clients}}:</div>
            <app-multiselect [filter]="filter.clients" (value)="changeFilterWhere(this.filter.clients.title)"></app-multiselect>
          </div>
          <div class="bma_flt_detail">
            <div>{{language.itemInfo.status}}:</div>
            <app-multiselect [filter]="filter.status" (value)="changeFilterWhere(this.filter.status.title)"></app-multiselect>
          </div>
        </div>
      </div>
      <div>
        <div class="bma_flt_clear">
          <i class="bi bi-backspace-fill"></i>
        </div>
        <div class="bma_flt_submit" (click)="submitFilterGateway(true)">
          <i class="bi bi-funnel-fill"></i>
          <span style="padding-left: 5px; font-size: 13px;">SEARCH</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="dl_tab_header row">
  <div class="dl_tab_hdr_capption col-lg-6">
    Caption
  </div>
  <div class="dl_tab_hdr_tools col-lg-6">
    Tools
  </div>
</div>
<div class="dl_tab_panel row">
  <table class="bma_table">
    <thead class="bma_table_header">
      <tr style="text-transform: uppercase;">
        <th class="bma_table_cell">
          <div style="height: 18px;">
            <label class="ss_checkbox">
              <input type="checkbox" [(ngModel)]="isSelectedAll" (change)="selectAllGateway()">
              <span class="ss_checkmark"></span>
            </label>
          </div>
        </th>
        <th class="bma_table_cell">
          <span>##</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.itemInfo.status}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.common.gateway}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.common.client}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.itemInfo.version}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.itemInfo.error}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.itemInfo.lastUpdate}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr [ngClass]="{'bma_row_active': gateway.isSelected}" 
          *ngFor="let gateway of gateways; let gatewayIdx = index;">
        <td class="bma_table_cell">
          <div style="height: 18px;">
            <label class="ss_checkbox">
              <input type="checkbox" [(ngModel)]="gateway.isSelected" (change)="selectGateway(gatewayIdx, gateway.isSelected)">
              <span class="ss_checkmark"></span>
            </label>
          </div>
        </td>
        <td class="bma_table_cell">
          <span>{{pageSize*pageIndexs[pageIndex] + gatewayIdx + 1}}</span>
        </td>
        <td class="bma_table_cell_W85" (click)="gatewayDetail(gatewayIdx)">
          <div [ngClass]="{'g_status_online': gateway.isOnline, 'g_status_offline': !gateway.isOnline}"> 
            <div>
              <i class="bi bi-circle-fill"></i>
            </div>
            <div style="padding-left: 5px;">{{ gateway.isOnline ? 'Online': "Offline "}}</div>
          </div>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{gateway.name}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{gateway.clientName}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{gateway.version}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{gateway.lastErrors}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{gateway.lastUpdate | date:'dd/MM/yyyy HH:mm:ss'}}</span>
        </td>
      </tr>
    </tbody>
    <thead class="bma_table_header">
      <tr>
        <th class="bma_table_cell">
            <span></span>
        </th>
        <th class="bma_table_cell">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
      </tr>
    </thead>
  </table>
  <div class="bpanel_paging">
    <div *ngIf="pageIndexs[1]*pageSize<numberGateways" class="bpanel_pager_nav">
      <div class="bpanel_pager_link" (click)="nextPage(-1)">
        <i class="bi bi-chevron-double-left"></i>
      </div>
      <div *ngIf="pageIndexs[1]*pageSize<numberGateways" class="bpanel_pager_link">
        <i class="bi bi-chevron-left" (click)="nextPage(-3)"></i>
      </div>
      <div *ngIf="pageIndexs[1]*pageSize<numberGateways" class="bpanel_pager_link bpanel_pager_number" 
        [ngClass]="{'bpanel_pager_number_select': pageIndex===0}" 
        (click)="changePageNumber(0)">{{pageIndexs[0] + 1}}
      </div>
      <div *ngIf="pageIndexs[1]*pageSize<numberGateways" class="bpanel_pager_link bpanel_pager_number" 
        [ngClass]="{'bpanel_pager_number_select': pageIndex===1}" 
        (click)="changePageNumber(1)">{{pageIndexs[1] + 1}}
      </div>
      <div *ngIf="pageIndexs[2]*pageSize<numberGateways" class="bpanel_pager_link bpanel_pager_number" 
      [ngClass]="{'bpanel_pager_number_select': pageIndex===2}" 
      (click)="changePageNumber(2)">{{pageIndexs[2] + 1}}
    </div>
    <div *ngIf="pageIndexs[1]*pageSize<numberGateways" class="bpanel_pager_link" (click)="nextPage(3)">
      <i class="bi bi-chevron-right"></i>
    </div>
    <div *ngIf="pageIndexs[1]*pageSize<numberGateways" class="bpanel_pager_link" (click)="nextPage(0)">
      <i class="bi bi-chevron-double-right"></i>
    </div>
    </div>
    <div class="bpanel_pager_info">
      <div class="bpanel_pi_size" clickOutside (clickOutside)="isPageSizeUp=false" (click)="isPageSizeUp ? isPageSizeUp=false : isPageSizeUp=true">
        <div class="bpanel_pi_selects" *ngIf="isPageSizeUp">
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 5}" (click)="changePageSize(0)">5</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 10}" (click)="changePageSize(1)">10</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 20}" (click)="changePageSize(2)">20</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 30}" (click)="changePageSize(3)">30</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 50}" (click)="changePageSize(4)">50</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 100}" (click)="changePageSize(5)">100</div>
        </div>
        <span style="padding-right: 10px;">{{pageSize}}</span>
        <i class="bma_filter_icon bi bi-chevron-down"></i>
      </div>
      <div class="bpanel_pi_detail">Displaying {{recordStart}} - {{recordEnd}} Of {{numberGateways}} Records</div>
    </div>
  </div>
</div>
<!-- <app-device-detail-popup *ngIf="popup.name===popup.deviceDetail" (popup)="closePopup()"></app-device-detail-popup>
<app-advance-settings-popup *ngIf="popup.name===popup.advanceSettings" [device]="devices[selected.index]" (popup)="closePopup()"></app-advance-settings-popup>
<app-change-state-popup *ngIf="popup.name===popup.changeState.name" [changeState]="popup.changeState" (popup)="closePopup()"></app-change-state-popup> -->