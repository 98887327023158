<div class="login_signin">
  <div class="login_lbl_head">
    <h3 class="auth_title">{{language.auth.login}}</h3>
  </div>
  <div class="login_body">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
      <div *ngIf="auth.alerts.length > 0" class="login_alert">
        <li *ngFor="let alert of auth.alerts" >
          {{alert}}
        </li>
      </div>
      <div class="login_ipt form-group">
        <input type="text" class="login_username form-control" id="username" placeholder="Email"
              required
              [(ngModel)]="auth.username"
              name="email"
              #username="ngModel">
      </div>
      <div class="login_ipt form-group">
        <input type="password"  class="login_password form-control" id="password" placeholder="Password"
              required
              [(ngModel)]="auth.password"
              name="password"
              #password="ngModel">
      </div>
      <div class="login_forget_password">{{language.auth.forgetPassword}}?</div>
      <button class="login_btn_signin" (click)="onSignin()">{{language.auth.signIn}}</button>
    </form>
  </div>
</div>