export const API = {
    rootAPI: 'https://airi.freedomks.ml:3002/api/',
    // rootAPI: 'http://192.168.1.46:3000/api/',
    changeStream: 'Devices/change-stream',
    users: 'Users',
    userLogin: 'Users/login',
    userLogout: 'Users/logout',
    getMe: 'Users/me',
    devices: 'Devices',
    numberDevices: 'Devices/count',
    deviceSettings: 'settings',
    changeState: 'Devices/changeState',
    clients: 'Clients',
    gateways: 'Gateways',
    numberGateways: 'Gateways/count',
    actions: 'Actions',
    numberActions: 'Actions/count',
};

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AUTH } from './local.storage';

@Injectable({
  providedIn: 'root'
})
export class APIService {
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: AUTH.token,
      }
    ),
    params: new HttpParams(),
  };

  constructor(
    private http: HttpClient,
  ) { }

  apiGet(url: string, filterName: string, filterValue: string): any {
    const httpOptions = { ...this.httpOptions };
    httpOptions.headers = httpOptions.headers.set('Authorization', AUTH.token);
    httpOptions.params = new HttpParams().set(filterName, `${filterValue}`);
    return  this.http.get(url, httpOptions);
  }

  async apiGetAsync(url: string, filterName: string, filterValue: string): Promise<any> {
    let res = {};
    const httpOptions = { ...this.httpOptions };
    httpOptions.headers = httpOptions.headers.set('Authorization', AUTH.token);
    httpOptions.params = new HttpParams().set(filterName, `${filterValue}`);
    await this.http.get(url, httpOptions).toPromise()
    .then(response => {
        res = response;
    })
    .catch(err => {
        this.apiCheckError(err.error.error);
    });
    return JSON.stringify(res);
  }

  apiPost(url: string, body: any, filterName: string = '', filterValue: string = ''): any {
    const httpOptions = { ...this.httpOptions };
    httpOptions.headers = httpOptions.headers.set('Authorization', AUTH.token);
    return  this.http.post(url, '', httpOptions);
  }

  async apiPostAsync(url: string, body: any, filterName: string = '', filterValue: string = ''): Promise<any> {
    let res = {};
    const httpOptions = { ...this.httpOptions };
    httpOptions.headers = httpOptions.headers.set('Authorization', AUTH.token);
    if (filterName) {
      httpOptions.params = new HttpParams().set(filterName, `${filterValue}`);
    }
    await this.http.post(url, body, httpOptions).toPromise()
    .then(response => {
        res = response;
    })
    .catch(err => {
        this.apiCheckError(err.error.error);
    });
    return JSON.stringify(res);
  }

  apiPatch(url: string, body: any, filterName: string = '', filterValue: string = ''): any {
    const httpOptions = { ...this.httpOptions };
    httpOptions.headers = httpOptions.headers.set('Authorization', AUTH.token);
    if (filterName) {
      httpOptions.params = new HttpParams().set(filterName, `${filterValue}`);
    }
    return this.http.patch(url, body, httpOptions);
  }

  apiCheckError(error: any): void {
    console.log('apiCheckError', error);
    if (error.status === 401 || error.statusCode === 401) {
        AUTH.token = '';
        AUTH.logo = 'https://airi.freedomks.ml/assets/app/media/img//user/avatar.svg';
        const auth = {
          token: AUTH.token,
          tokenCreated: '',
          tokenTTL: '',
        };
        localStorage.setItem('auth', JSON.stringify(auth));
      }
  }
}
