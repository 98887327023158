import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SystemComponent } from './system.component';
import { SystemRoutingModule } from './system-routing.module';

import { ShareModule } from '../share.module';
import { DashboardModule } from './body/dashboard/dashboard.module';
import { AutomationModule } from './body/automation/automation.module';
import { DeviceLayoutModule } from './body/device-layout/device-layout.module';
import { ClientsModule } from './body/clients/clients.module';
import { GatewayLayoutModule } from './body/gateway-layout/gateway-layout.module';

import { 
  HeaderComponent,
  BodyComponent,
  FooterComponent,

} from './system.import';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SystemRoutingModule,
    ShareModule,
    DashboardModule,
    AutomationModule,
    DeviceLayoutModule,
    ClientsModule,
    GatewayLayoutModule,
  ],
  declarations: [
    SystemComponent,
    HeaderComponent,
    BodyComponent,
    FooterComponent,
  ],
 
})
export class SystemModule { }
