<div class="cpn_text">
  <input class="cpn_txt_value" placeholder="{{language.itemInfo.enter + ' ' + title}}"
    [ngClass]="{'cpn_txt_val_unit': text.unit || units, 'ss_cmn_required_border': require && !text.value.length}" 
    [(ngModel)]="text.value">
  <!-- <div class="cpn_txt_unit" *ngIf="text.unit">
    <span>{{text.unit}}</span>
  </div> -->
  <span class="cpn_txt_unit" *ngIf="text.unit">{{text.unit}}</span>
  <app-selection class="cpn_txt_unit" *ngIf="units" 
    [selection]="units.selection" [selected]="units.selected"></app-selection>
</div>