<div class="bma_tool_bar row">
  <div class="col-lg-6" style="display: flex; align-self: center;">
    <div class="bma_dropdown">
      <div class="bma_dropdown_menu" (click)="showPopup(popup.create)">
        <i class="bi bi-plus" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">Create</span>
      </div>
      <div class="bma_dropdown_menu" *ngIf="selected.number===1" (click)="showPopup(popup.actionDetail)">
        <i class="bi bi-file-earmark-text" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">Detail</span>
      </div>
      <div class="bma_dropdown_menu" *ngIf="selected.number===1">
        <i class="bi bi-pencil-square" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">Edit</span>
      </div>
      <div class="bma_dropdown_menu" *ngIf="selected.number===1">
        <i class="bi bi-gear-wide-connected" style="vertical-align: middle;"></i>
        <div class="bma_dm_content">
          <div class="bma_dmc_title">SETTINGS</div>
          <div class="bma_dmc_select">
            <i class="bi bi-gear"></i>
            <span style="padding-left: 10px;" >Common Settings</span>
          </div>
          <div class="bma_dmc_select" (click)="showPopup(popup.edit)">
            <i class="bi bi-gear"></i>
            <span style="padding-left: 10px;">Advance Settings</span>
          </div>
        </div>
      </div>
      <div class="bma_dropdown_menu" *ngIf="popup.changeState.active" 
          (click)="showPopup(popup.changeState.name)">
        <i class="bi bi-power" style="vertical-align: middle;"></i>
        <span class="bma_tooltip">On/Off</span>
      </div>
    </div>
  </div>
  <div class="bma_filter col-lg-6">
    <div class="bma_flt_header" (click)="filterDrop()">
      <i class="bi bi-funnel"></i>
      <span class="bma_flt_title">{{language.itemInfo.filters}}</span>
      <i class="bma_flt_icon" [ngClass]="{'bi bi-chevron-down': !filter.main.isDrop, 'bi bi-chevron-up':filter.main.isDrop}"></i>
    </div>
    <div class="bma_flt_body" *ngIf="filter.main.isDrop">
      <div class="bma_flt_content">
        <div style="padding-right: 10px;">
          <div  class="bma_flt_detail"> 
            <div>{{language.deviceDetail.deviceName}}:</div>
            <input class="bma_selected" type="text" [(ngModel)]="filter.name.selectedValue" (input)="filter.name.isChange=true; filter.name.isDrop=true; filter.main.isChange=true" placeholder="{{language.deviceDetail.enterName}}">
          </div>
          <div *ngIf="!auth.clientId" class="bma_flt_detail">
            <div>{{language.common.clients}}:</div>
            <app-multiselect [filter]="filter.clients" (value)="changeFilterWhere(this.filter.clients.title)"></app-multiselect>
          </div>
          <div class="bma_flt_detail">
            <div>{{language.common.projects}}:</div>
            <div [ngClass]="{'bma_flt_disable': !auth.clientId && filter.clients.selectedValue.length===0}">
              <app-multiselect [filter]="filter.projects"></app-multiselect>
            </div>
          </div>
          <div class="bma_flt_detail">
            <div>{{language.common.areas}}:</div>
            <div [ngClass]="{'bma_flt_disable': filter.projects.selectedValue}">
              <app-multiselect [ngClass]="{'bma_flt_disable': filter.areas.list}" [filter]="filter.areas"></app-multiselect>
            </div>
          </div>
          <div class="bma_flt_detail">
            <div>{{language.deviceDetail.deviceType}}:</div>
            <app-multiselect [filter]="filter.deviceType" (value)="changeFilterWhere(this.filter.deviceType.title)"></app-multiselect>
          </div>
          <div class="bma_flt_detail">
            <div>{{language.itemInfo.status}}:</div>
            <app-multiselect [filter]="filter.status" (value)="changeFilterWhere(this.filter.status.title)"></app-multiselect>
          </div>
          <div class="bma_flt_detail">
            <div>Gateways:</div>
            <div [ngClass]="{'bma_flt_disable': !auth.clientId && (filter.clients.selectedValue.length===0 || filter.clients.isChange)}">
              <app-multiselect [filter]="filter.gateways" (value)="changeFilterWhere(this.filter.gateways.title)"></app-multiselect>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="bma_flt_clear">
          <i class="bi bi-backspace-fill"></i>
        </div>
        <div class="bma_flt_submit" (click)="submitFilterDevice(true)">
          <i class="bi bi-funnel-fill"></i>
          <span style="padding-left: 5px; font-size: 13px;">SEARCH</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="dl_tab_panel row">
  <table class="bma_table">
    <thead class="bma_table_header">
      <tr style="text-transform: uppercase;">
        <th class="bma_table_cell">
          <div style="height: 18px;">
            <label class="ss_checkbox">
              <input type="checkbox" [(ngModel)]="isSelectedAlls.actions" (change)="selectAllActions()">
              <span class="ss_checkmark"></span>
            </label>
          </div>
        </th>
        <th class="bma_table_cell">
          <span>##</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.itemInfo.status}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.automation.action}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.automation.script}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.automation.condition}}</span>
        </th>
        <th class="bma_table_cell_W85">
          <span>{{language.automation.schedule}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr [ngClass]="{'bma_row_active': action.isSelected}" 
          *ngFor="let action of actions; let actionIdx = index">
        <td class="bma_table_cell">
          <div style="height: 18px;">
            <label class="ss_checkbox">
              <input type="checkbox" [(ngModel)]="action.isSelected" (change)="selectDevice(actionIdx, action.isSelected)">
              <span class="ss_checkmark"></span>
            </label>
          </div>
        </td>
        <td class="bma_table_cell">
          <span>{{pageSize*pageIndexs[pageIndex] + actionIdx + 1}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <div [ngClass]="{'d_status_online': action.status, 'd_status_offline': !action.status}"> 
            <i class="bi bi-circle-fill"></i>
            <span style="padding-left: 5px;">{{ action.status ? language.common.statusOnline: language.common.statusOffline}}</span>
          </div>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{action.name}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{action.script + ''}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{action.condition + ''}}</span>
        </td>
        <td class="bma_table_cell_W85">
          <span>{{action.schedule.timeStart}}</span>
          <span>{{action.schedule.timeEnd}}</span>
          <span>{{action.schedule.repeat}}</span>
        </td>
      </tr>
    </tbody>
    <thead class="bma_table_header">
      <tr>
        <th class="bma_table_cell">
            <span></span>
        </th>
        <th class="bma_table_cell">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
        <th class="bma_table_cell_W85">
          <span></span>
        </th>
      </tr>
    </thead>
  </table>
  <div class="bpanel_paging">
    <div *ngIf="pageIndexs[1]*pageSize<numberActions" class="bpanel_pager_nav">
      <div class="bpanel_pager_link" (click)="nextPage(-1)">
        <i class="bi bi-chevron-double-left"></i>
      </div>
      <div *ngIf="pageIndexs[1]*pageSize<numberActions" class="bpanel_pager_link">
        <i class="bi bi-chevron-left" (click)="nextPage(-3)"></i>
      </div>
      <div *ngIf="pageIndexs[1]*pageSize<numberActions" class="bpanel_pager_link bpanel_pager_number" 
        [ngClass]="{'bpanel_pager_number_select': pageIndex===0}" 
        (click)="changePageNumber(0)">{{pageIndexs[0] + 1}}
      </div>
      <div *ngIf="pageIndexs[1]*pageSize<numberActions" class="bpanel_pager_link bpanel_pager_number" 
        [ngClass]="{'bpanel_pager_number_select': pageIndex===1}" 
        (click)="changePageNumber(1)">{{pageIndexs[1] + 1}}
      </div>
      <div *ngIf="pageIndexs[2]*pageSize<numberActions" class="bpanel_pager_link bpanel_pager_number" 
      [ngClass]="{'bpanel_pager_number_select': pageIndex===2}" 
      (click)="changePageNumber(2)">{{pageIndexs[2] + 1}}
    </div>
    <div *ngIf="pageIndexs[1]*pageSize<numberActions" class="bpanel_pager_link" (click)="nextPage(3)">
      <i class="bi bi-chevron-right"></i>
    </div>
    <div *ngIf="pageIndexs[1]*pageSize<numberActions" class="bpanel_pager_link" (click)="nextPage(0)">
      <i class="bi bi-chevron-double-right"></i>
    </div>
    </div>
    <div class="bpanel_pager_info">
      <div class="bpanel_pi_size" clickOutside (clickOutside)="isPageSizeUp=false" (click)="isPageSizeUp ? isPageSizeUp=false : isPageSizeUp=true">
        <div class="bpanel_pi_selects" *ngIf="isPageSizeUp">
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 5}" (click)="changePageSize(0)">5</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 10}" (click)="changePageSize(1)">10</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 20}" (click)="changePageSize(2)">20</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 30}" (click)="changePageSize(3)">30</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 50}" (click)="changePageSize(4)">50</div>
          <div class="bpanel_pi_select" [ngClass]="{'bpanel_pi_selected': pageSize === 100}" (click)="changePageSize(5)">100</div>
        </div>
        <span style="padding-right: 10px;">{{pageSize}}</span>
        <i class="bma_filter_icon bi bi-chevron-down"></i>
      </div>
      <div class="bpanel_pi_detail">Displaying {{recordStart}} - {{recordEnd}} Of {{numberActions}} Records</div>
    </div>
  </div>
</div>
<app-action-popup *ngIf="popup.name===popup.create" (popup)="closePopup()"></app-action-popup>
<!-- <app-action-popup (popup)="closePopup()"></app-action-popup> -->