import { Injectable } from '@angular/core';

import { API, APIService } from '../../../_services/api';
import { ClientInfo } from './clients.model';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  clientList: ClientInfo[] = [];
  
  constructor(
    private apiService: APIService,
  ) { }

  async getClients(filter: string) {
    let url = API.rootAPI + API.clients;
    return await this.apiService.apiGetAsync(url, 'filter', filter);
  }

  getClientList() {
    return this.clientList;
  }
}
