import { Injectable } from '@angular/core';
import { HttpClient, HttpClientJsonpModule, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { LANGUAGES } from '../_services/languages';
import { API, APIService } from '../_services/api';
import { AUTH } from '../_services/local.storage';
import { SYSTEM } from './auth.import';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpOptions = {
    headers: new HttpHeaders(
      { 'Content-Type': 'application/json'}
    )
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private apiService: APIService,
  ) { }

  doSignIn(): void {
    const url = API.rootAPI + API.userLogin;
    this.http.post(url, AUTH, this.httpOptions).subscribe(
      (response: any) => {
        console.log(response);
        AUTH.token = response.data.id;
        const tokenCreated = '' + Math.floor(new Date(response.data.created).getTime() / 1000);
        const tokenTTL = response.data.ttl;
        const auth = {
          token: AUTH.token,
          tokenCreated,
          tokenTTL,
        };
        localStorage.setItem('auth', JSON.stringify(auth));
        this.router.navigate([SYSTEM.menu.dashboard]);
      },
      (error) => {
        console.log(error);
        if (error.status > 0) {
          AUTH.alerts.length = 0;
          AUTH.alerts.push('Username and password do not match.');
        } else {
          AUTH.alerts.length = 0;
          AUTH.alerts.push('Lam cai xoay xoay o nut SignIn');
        }
      });
  }

  doSignOut(): void {
    const url = API.rootAPI + API.userLogout;
    this.apiService.apiPost(url, '{}').subscribe(
      (response: any) => {
        console.log(response);
        AUTH.token = '';
        AUTH.logo = 'https://airi.freedomks.ml/assets/app/media/img//user/avatar.svg';
        const auth = {
          token: AUTH.token,
          tokenCreated: '',
          tokenTTL: '',
        };
        localStorage.setItem('auth', JSON.stringify(auth));
        this.router.navigate(['login']);
      },
      (error: any) => {
        console.log(error);
      });
  }

  checkToken() {
    let auth: any = localStorage.getItem('auth');
    auth = auth ? JSON.parse(auth) : {};
    let now = Math.floor(new Date().getTime()/1000);
    let token = auth?.token || '';
    let tokenCreated = +auth?.tokenCreated || 0;
    let tokenTTL = +auth?.tokenTTL || 0;

    AUTH.token = '';
    if (token) {
      if (now - tokenCreated > tokenTTL) {
        return false;
      } else {
        AUTH.token =  token;
        return true;
      }
    }

    return false;
  }
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    ) {  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (this.authService.checkToken()) {
      return true;
    } else {
      // this.authService.doSignOut();
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
